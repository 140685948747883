import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Card,
    LoadingSpinner,
    PageBackButton,
    PageTitle,
    RobotGenerationSelector,
    TitleValueInput,
    tooltipStyles
} from "components/utils/ui";
import { apiEndpoints, apiRequest } from "services/api";
import { routes, routeWithParams } from "helpers/routes";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";

const NewRobot = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [robotId, setRobotId] = useState("");
    const [generation, setGeneration] = useState(null);
    const [slackChannelId, setSlackChannelId] = useState("");
    const [storageAddress, setStorageAddress] = useState("");
    const [storageCity, setStorageCity] = useState("");
    const [storageState, setStorageState] = useState("");
    const [storageZip, setStorageZip] = useState("");
    const [storageCountry, setStorageCountry] = useState("");

    const [isCreatingRobot, setIsCreatingRobot] = useState(false);
    const [data, setData] = useState(undefined);
    const [error, setError] = useState(undefined);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const createRobot = () => {
        if (!robotId || !generation) {
            window.alert(t("robotNameAndGenerationRequired"));
            return;
        }
        setIsCreatingRobot(true);
        apiRequest(
            setData,
            setError,
            "post",
            apiEndpoints.robots,
            "",
            {
                robotId: robotId,
                generation: generation,
                slackChannelId: slackChannelId,
                storageAddress: storageAddress,
                storageCity: storageCity,
                storageState: storageState,
                storageZip: storageZip,
                storageCountry: storageCountry
            }
        ).then(apiRequestSuccess => {
            if (!apiRequestSuccess) alert(t("errorCreatingRobot"));
            setIsCreatingRobot(false);
        });
    }

    useEffect(() => {
        if (data === undefined || !data.robotId) return;
        navigate(routeWithParams(routes.robot.path, {id: data.robotId}));
    }, [data]);

    return (
        <>
            <PageTitle className="align-items-center d-flex">
                <div>
                    <PageBackButton className="mb-1"
                                    baseUrl={routes.robots.path}>{t("robots")}</PageBackButton>
                    {t("newRobot")}
                </div>
                <div className="flex-grow-1"></div>
            </PageTitle>
            <div className="d-flex flex-wrap">
                <div className="col-12 col-lg-6">
                    <Card>
                        <div className="fw-bold text-size-lg">{t("summary")}</div>
                        <div className="d-flex mt-2">
                            <TitleValueInput
                                title={`${t("name")}:`}
                                className="flex-grow-1"
                                value={robotId}
                                setValue={setRobotId}
                            />
                            {!robotId && (
                                <>
                                    <FontAwesomeIcon icon={faExclamationCircle} className="text-color-accent"
                                                     data-tooltip-id="name-required-tooltip"/>
                                    <Tooltip
                                        id={`name-required-tooltip`}
                                        place="left"
                                        content={t("robotNameRequired")}
                                        style={tooltipStyles}
                                    />
                                </>
                            )}
                        </div>
                        <div className="d-flex">
                            <div className="fw-bold me-1">{`${t("generation")}:`}</div>
                            <div className="text-color-med flex-grow-1">
                                <RobotGenerationSelector loaded={true} robotGeneration={generation}
                                                         setRobotGeneration={setGeneration}/>
                            </div>
                            {!generation && (
                                <>
                                    <FontAwesomeIcon icon={faExclamationCircle} className="text-color-accent"
                                                     data-tooltip-id="generation-required-tooltip"/>
                                    <Tooltip
                                        id={`generation-required-tooltip`}
                                        place="left"
                                        content={t("robotGenerationRequired")}
                                        style={tooltipStyles}
                                    />
                                </>
                            )}
                        </div>
                        <TitleValueInput
                            title={`${t("slackChannelId")}:`}
                            value={slackChannelId}
                            setValue={setSlackChannelId}
                            className="mt-3"
                        />
                        <div className="align-items-center d-flex fst-italic text-color-med mt-2">
                            <FontAwesomeIcon icon={faCircleInfo} className="pe-1 text-size-xs"/>
                            {t("fieldsCannotBeChangedLater")}
                        </div>
                    </Card>
                </div>

                <div className="col-12 col-lg-6">
                    <Card>
                        <div className="fw-bold text-size-lg">{t("storageLocation")}</div>
                        <TitleValueInput
                            title={`${t("address")}:`}
                            value={storageAddress}
                            setValue={setStorageAddress}
                            className="mt-2"
                        />
                        <TitleValueInput
                            title={`${t("city")}:`}
                            value={storageCity}
                            setValue={setStorageCity}
                        />
                        <TitleValueInput
                            title={`${t("state")}:`}
                            value={storageState}
                            setValue={setStorageState}
                        />
                        <TitleValueInput
                            title={`${t("zip")}:`}
                            value={storageZip}
                            setValue={setStorageZip}
                        />
                        <TitleValueInput
                            title={`${t("country")}:`}
                            value={storageCountry}
                            setValue={setStorageCountry}
                        />
                    </Card>
                </div>
                <div className="col-12 my-2 my-lg-3 d-flex justify-content-center">
                    {isCreatingRobot ? (
                        <LoadingSpinner size={38}/>
                    ) : (
                        <>
                            <div className="col-6 text-end">
                                <button type="button" className="btn bg-accent me-3 text-white"
                                        onClick={() => createRobot()} disabled={!robotId || !generation}>
                                    {t("save")}
                                </button>
                            </div>
                            <div className="col-6">
                                <button type="button" className="btn bg-med ms-3 text-white" onClick={() => {
                                    navigate(routes.robots.path);
                                }}>
                                    {t("cancel")}
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export { NewRobot };

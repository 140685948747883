import React, { useEffect, useState } from "react";
import "./index.css";
import { useTranslation } from "react-i18next";
import {
    Calendar,
    Card,
    colors,
    DeploymentCalendarLegend,
    FullPageLoading,
    LoadingSpinner,
    PageTitle, RobotCalendarLegend,
    SearchableSelect,
    StaffCalendarLegend,
    styleCalendarData
} from "components/utils/ui";
import { apiEndpoints, apiRequest, createEmptyApiResponse } from "services/api";
import { useNavigate, useParams } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { convertToPrettyDate, dateInRange, dateObjectToDateString } from "helpers/date";
import { routes, routeWithParams } from "helpers/routes";
import { listToSentence } from "helpers/sentence";
import { useHotkeys } from "react-hotkeys-hook";

const Assignment = () => {
    const {t} = useTranslation();
    const {id, page, type} = useParams();
    const navigate = useNavigate();

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);

    const [assignmentType, setAssignmentType] = useState(page === assignmentPages.new && !!type ? type : null);
    const [assignee, setAssignee] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [travelStartDate, setTravelStartDate] = useState(new Date());
    const [travelEndDate, setTravelEndDate] = useState(new Date());

    const [isAssigning, setIsAssigning] = useState(false);

    useHotkeys("esc", () => navigateBack());

    useEffect(() => {
        let getDataApiEndpoint;
        if (page === assignmentPages.new) getDataApiEndpoint = apiEndpoints.assignmentOptions;
        if (page === assignmentPages.update) getDataApiEndpoint = apiEndpoints.assignment;
        createEmptyApiResponse(`get`, getDataApiEndpoint).then(emptyApiResponse => {
            if (!data && emptyApiResponse) setData(emptyApiResponse);
        });
        apiRequest(setData, setError, "get", getDataApiEndpoint, id).then(apiRequestSuccess => {
            if (apiRequestSuccess) setLoaded(true);
        });
    }, []);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    useEffect(() => {
        if (!!data) {
            if (page === assignmentPages.new) {
                setStartDate(new Date(data.deployment.startDate + "T00:00:00"));
                setEndDate(new Date(data.deployment.endDate + "T00:00:00"));
                setTravelStartDate(new Date(data.deployment.travelStartDate + "T00:00:00"));
                setTravelEndDate(new Date(data.deployment.travelEndDate + "T00:00:00"));
            }
            if (page === assignmentPages.update) {
                setStartDate(new Date(data.assignment.startDate + "T00:00:00"));
                setEndDate(new Date(data.assignment.endDate + "T00:00:00"));
                setTravelStartDate(new Date(data.assignment.travelStartDate + "T00:00:00"));
                setTravelEndDate(new Date(data.assignment.travelEndDate + "T00:00:00"));
                if (!!data.assignment.robotId) {
                    setAssignmentType(assignmentTypes.robots);
                    setAssignee(data.assignment.robotId);
                }
                if (!!data.assignment.userId) {
                    if (!!data.operators[data.assignment.userId]) setAssignmentType(assignmentTypes.operators);
                    if (!!data.staff[data.assignment.userId]) setAssignmentType(assignmentTypes.staff)
                    setAssignee(data.assignment.userId);
                }
            }
        }
    }, [data]);

    const assignmentTypesOptions = {
        [assignmentTypes.robots]: t("robots"),
        [assignmentTypes.operators]: t("operators"),
        [assignmentTypes.staff]: t("supportStaff")
    }

    useEffect(() => {
        if (travelStartDate > startDate) setStartDate(travelStartDate);
    }, [travelStartDate]);
    useEffect(() => {
        if (startDate > endDate) setEndDate(startDate);
        if (startDate < travelStartDate) setTravelStartDate(startDate);
    }, [startDate]);
    useEffect(() => {
        if (endDate > travelEndDate) setTravelEndDate(endDate);
        if (endDate < startDate) setStartDate(endDate)
    }, [endDate]);
    useEffect(() => {
        if (travelEndDate < endDate) setEndDate(travelEndDate);
    }, [travelEndDate]);

    const handleSelect = (ranges) => {
        if (ranges.travelDates) {
            const newTravelStartDate = ranges.travelDates.startDate;
            const newTravelEndDate = ranges.travelDates.endDate;
            setTravelStartDate(newTravelStartDate);
            setTravelEndDate(newTravelEndDate);
        }
        if (ranges.dates) {
            const newStartDate = ranges.dates.startDate;
            const newEndDate = ranges.dates.endDate;
            setStartDate(newStartDate);
            setEndDate(newEndDate);
        }
    };

    const ranges = [
        {
            startDate: travelStartDate,
            endDate: travelEndDate,
            key: "travelDates",
            color: "#637378",
            autoFocus: true
        },
        {
            startDate: startDate,
            endDate: endDate,
            key: "dates",
            color: "#f25c07",
            autoFocus: true
        },
    ];

    const processEntities = (entities) => {
        let options = {};
        Object.keys(entities).forEach(entity => {
            options[entities[entity].id] = entities[entity].name;
        });
        return options;
    };

    const assign = () => {
        let conflicts = [];
        data[assignmentType][assignee].thisDeployment.forEach(date => {
            if (dateInRange(date, dateObjectToDateString(travelStartDate), dateObjectToDateString(travelEndDate))) {
                conflicts.push(convertToPrettyDate(date));
            }
        });
        if (conflicts.length > 0) {
            alert(t("assignedToThisDeploymentError", {
                assignee: data[assignmentType][assignee].name,
                dates: listToSentence(conflicts)
            }));
            return;
        }
        const checks = {
            otherDeployments: "assignedToOtherDeploymentConfirmation",
            pto: "ptoConfirmation",
            uft: "uftConfirmation",
        };
        let proceed = true;
        Object.keys(checks).some(check => {
            let conflicts = [];
            data[assignmentType][assignee][check].forEach(date => {
                if (dateInRange(date, dateObjectToDateString(travelStartDate), dateObjectToDateString(travelEndDate))) {
                    conflicts.push(convertToPrettyDate(date));
                }
            });
            if (conflicts.length > 0) {
                if (!window.confirm(t(`${checks[check]}`, {
                    assignee: data[assignmentType][assignee].name,
                    dates: listToSentence(conflicts)
                }))) {
                    proceed = false;
                    return true;
                }
            }
        })
        if (!proceed) return;
        setIsAssigning(true);
        if (page === assignmentPages.new) {
            apiRequest(
                (data) => {
                },
                setError,
                "post",
                apiEndpoints.newAssignment,
                "",
                {
                    deploymentId: data.deployment.deploymentId,
                    startDate: dateObjectToDateString(startDate),
                    endDate: dateObjectToDateString(endDate),
                    travelStartDate: dateObjectToDateString(travelStartDate),
                    travelEndDate: dateObjectToDateString(travelEndDate),
                    robotId: assignmentType === assignmentTypes.robots ? assignee : null,
                    userId: assignmentType === assignmentTypes.operators || assignmentType === assignmentTypes.staff ? assignee : null,
                }
            ).then(apiRequestSuccess => {
                if (apiRequestSuccess) navigateBack();
                else {
                    alert(t("errorCreatingAssignment"));
                    setIsAssigning(false);
                }
            });
        }
        if (page === assignmentPages.update) {
            apiRequest(
                (data) => {
                },
                setError,
                "put",
                apiEndpoints.editAssignment,
                id,
                {
                    deploymentId: data.deployment.deploymentId,
                    startDate: dateObjectToDateString(startDate),
                    endDate: dateObjectToDateString(endDate),
                    travelStartDate: dateObjectToDateString(travelStartDate),
                    travelEndDate: dateObjectToDateString(travelEndDate),
                    robotId: assignmentType === assignmentTypes.robots ? assignee : null,
                    userId: assignmentType === assignmentTypes.operators || assignmentType === assignmentTypes.staff ? assignee : null,
                }
            ).then(apiRequestSuccess => {
                if (apiRequestSuccess) navigateBack();
                else alert(t("errorUpdatingAssignment"));
            });
        }
    }

    const navigateBack = () => navigate(routeWithParams(routes.deployment.path, {id: data.deployment.deploymentId}));


    const processCalendarData = (calendarData, includeCurrentAssignment = false) => {
        calendarData = calendarData.map(e => styleCalendarData(e));
        if (!!includeCurrentAssignment) {
            calendarData.push({
                name: data.deployment.name,
                url: null,
                travelStartDate: dateObjectToDateString(travelStartDate),
                startDate: dateObjectToDateString(startDate),
                endDate: dateObjectToDateString(endDate),
                travelEndDate: dateObjectToDateString(travelEndDate),
                color: colors.med,
                row: assignee,
            });
        }
        return calendarData;
    };

    if (data === undefined) {
        return <FullPageLoading/>;
    }

    return (
        <>
            <PageTitle>
                {loaded ? (
                    <>
                        {page === assignmentPages.new && t("newAssignment")}
                        {page === assignmentPages.update && t("updateAssignment")}
                    </>
                ) : (
                    <LoadingSpinner size={46}/>
                )}
            </PageTitle>
            <div className="d-flex flex-wrap align-items-stretch">
                <div className="d-flex flex-column col-12 col-lg-6">
                    <Card className="flex-grow-1">
                        <div
                            className="align-items-center d-flex flex-column h-100 justify-content-center">
                            <div className="fw-bold text-size-xl my-3">{t("assignee")}</div>
                            {loaded ? (
                                <>
                                    <div className="mb-3 col-6">
                                        <SearchableSelect loaded={loaded} options={{[id]: data.deployment.name}}
                                                          placeholder={t("selectDeployment")}
                                                          setSelectedOption={() => {
                                                          }} selectedOption={id}/>
                                    </div>
                                    <div className="mb-3 col-6">
                                        <SearchableSelect loaded={loaded} options={assignmentTypesOptions}
                                                          placeholder={t("selectAssignmentType")}
                                                          setSelectedOption={setAssignmentType}
                                                          selectedOption={assignmentType}/>
                                    </div>
                                </>
                            ) : (
                                <LoadingSpinner size={50}/>
                            )}
                            {loaded && assignmentType in assignmentTypes ? (
                                <div className="mb-3 col-6">
                                    <SearchableSelect loaded={loaded} options={processEntities(data[assignmentType])}
                                                      placeholder={t("selectAssignee")}
                                                      setSelectedOption={setAssignee} selectedOption={assignee}/>
                                </div>
                            ) : (
                                <div className="mb-3" style={{height: "25px"}}/>
                            )}
                        </div>
                    </Card>
                </div>
                <div className="d-flex flex-column col-12 col-lg-6">
                    <Card>
                        <div
                            className="align-items-center d-flex flex-column h-100 justify-content-center">
                            <div className="fw-bold text-size-xl my-3">{t("dates")}</div>
                            <DateRangePicker
                                minDate={data.deployment.travelStartDate ? new Date(data.deployment.travelStartDate + "T00:00:00") : new Date()}
                                maxDate={data.deployment.travelStartDate ? new Date(data.deployment.travelEndDate + "T00:00:00") : new Date()}
                                onChange={handleSelect}
                                ranges={ranges}
                                shownDate={data.deployment.travelStartDate ? new Date(data.deployment.travelStartDate + "T00:00:00") : new Date()}
                                staticRanges={[]}
                                inputRanges={[]}
                                retainEndDateOnFirstSelection={true}
                            />
                            <div className="align-items-center d-flex col-12 justify-content-center mb-3 w-100">
                                <FontAwesomeIcon icon={faCircle}
                                                 className="text-color-med"/>&nbsp;{t("traveling")}
                                <FontAwesomeIcon icon={faCircle}
                                                 className="text-color-accent ps-4"/>&nbsp;{t("deployed")}
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="col-12 my-2 my-lg-3 d-flex justify-content-center">
                    {isAssigning ? (
                        <LoadingSpinner size={38}/>
                    ) : (
                        <>
                            <div className="col-6 text-end">
                                <button type="button" className="btn bg-accent me-2 me-lg-3 text-white"
                                        onClick={() => assign()} disabled={!assignmentType || !assignee}>
                                    {page === assignmentPages.new && t("assign")}
                                    {page === assignmentPages.update && t("update")}
                                </button>
                            </div>
                            <div className="col-6">
                                <button type="button" className="btn bg-med ms-2 ms-lg-3 text-white"
                                        onClick={() => navigateBack()}>
                                    {t("cancel")}
                                </button>
                            </div>
                        </>
                    )}
                </div>
                <div className="col-12">
                    <Card>
                        <div
                            className="align-items-center d-flex flex-column h-100 justify-content-center">
                            <div className="fw-bold mb-3 text-size-xl">{t("schedule")}</div>
                            {assignmentType === assignmentTypes.robots ? (
                                <>
                                    <Calendar loaded={loaded} startDate={data.schedule.startDate}
                                              endDate={data.schedule.endDate}
                                              rows={data.schedule.robots}
                                              events={processCalendarData(data.schedule.robotSchedule, assignmentType === assignmentTypes.robots)}
                                              highlightStart={data.deployment.travelStartDate}
                                              highlightEnd={data.deployment.travelEndDate}
                                              initialDate={data.deployment.travelStartDate}/>
                                    <RobotCalendarLegend/>
                                </>
                            ) : (assignmentType === assignmentTypes.operators ? (
                                <>
                                    <Calendar loaded={loaded} startDate={data.schedule.startDate}
                                              endDate={data.schedule.endDate}
                                              rows={data.schedule.operators}
                                              events={processCalendarData(data.schedule.operatorSchedule, assignmentType === assignmentTypes.operators)}
                                              highlightStart={data.deployment.travelStartDate}
                                              highlightEnd={data.deployment.travelEndDate}
                                              initialDate={data.deployment.travelStartDate}/>
                                    <StaffCalendarLegend/>
                                </>
                            ) : (
                                <>
                                    <Calendar loaded={loaded} startDate={data.schedule.startDate}
                                              endDate={data.schedule.endDate}
                                              rows={data.schedule.staff}
                                              events={processCalendarData(data.schedule.staffSchedule, assignmentType === assignmentTypes.staff)}
                                              highlightStart={data.deployment.travelStartDate}
                                              highlightEnd={data.deployment.travelEndDate}
                                              initialDate={data.deployment.travelStartDate}/>
                                    <StaffCalendarLegend/>
                                </>
                            ))}
                        </div>
                    </Card>
                </div>
            </div>
        </>
    );
};

const assignmentPages = {
    "new": "new",
    "update": "update"
};

const assignmentTypes = {
    "robots": "robots",
    "operators": "operators",
    "staff": "staff",
};

export { assignmentPages, assignmentTypes, Assignment };

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Card,
    CopyButton,
    DownloadButton,
    FullPageLoading,
    LinkWithParams,
    LoadingSpinner,
    PageTitle,
    ProportionSummary,
    SearchableList,
    stateColors,
    TitleValueLine,
    tooltipStyles
} from "components/utils/ui";
import { apiEndpoints, apiRequest, createEmptyApiResponse } from "services/api";
import { routes } from "helpers/routes";
import { convertToPrettyDate } from "helpers/date";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircle, faExclamationTriangle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { uniqueId } from "helpers/random";
import { Area, AreaChart, CartesianGrid, Tooltip as RechartsTooltip, XAxis, YAxis } from "recharts";

const Fleet = () => {
    const {t} = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);

    const historicalStatusChartContainerRef = useRef(null);

    useEffect(() => {
        const getDataApiEndpoint = apiEndpoints.fleet;
        createEmptyApiResponse(`get`, getDataApiEndpoint).then(emptyApiResponse => {
            if (!data && emptyApiResponse) setData(emptyApiResponse);
        });
        apiRequest(setData, setError, `get`, getDataApiEndpoint).then(apiRequestSuccess => {
            if (apiRequestSuccess) setLoaded(true);
        })
    }, []);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    useEffect(() => {
        const scrollToRight = () => {
            if (historicalStatusChartContainerRef.current) {
                historicalStatusChartContainerRef.current.scrollLeft = historicalStatusChartContainerRef.current.scrollWidth;
            }
        };
        const timeoutId = setTimeout(scrollToRight, 0);
        return () => clearTimeout(timeoutId);
    }, [data]);

    const activeRobots = useMemo(() =>
            !data ? 0 : data.fleetStatus.deployed.quantity + data.fleetStatus.jobReady.quantity,
        [data]
    );

    const twoWeekPeakDemand = useMemo(() =>
            !data ? 0 : Math.max(data.thisWeek.robotsQuantity, data.nextWeek.robotsQuantity, data.twoWeeks.robotsQuantity),
        [data]
    );

    const fleetStatusText = () => {
        return `${data.fleetStatus.reserved.quantity}${t("times")} ${t("reserved")}: ${data.fleetStatus.reserved.list.join(`, `)}
${data.fleetStatus.deployed.quantity}${t("times")} ${t("deployed")}: ${data.fleetStatus.deployed.list.join(`, `)}
${data.fleetStatus.jobReady.quantity}${t("times")} ${t("jobReady")}: ${data.fleetStatus.jobReady.list.join(`, `)}
${data.fleetStatus.developmentReady.quantity}${t("times")} ${t("developmentReady")}: ${data.fleetStatus.developmentReady.list.join(`, `)}
${data.fleetStatus.inMaintenance.quantity}${t("times")} ${t("inMaintenance")}: ${data.fleetStatus.inMaintenance.list.join(`, `)}`;
    }

    const fleetCalibrationStatusText = () => {
        return `${data.fleetCalibrationStatus.calibrated.quantity}${t("times")} ${t("calibrated")}: ${data.fleetCalibrationStatus.calibrated.list.join(`, `)}
${data.fleetCalibrationStatus.checkInTesting.quantity}${t("times")} ${t("checkInTesting")}: ${data.fleetCalibrationStatus.checkInTesting.list.join(`, `)}
${data.fleetCalibrationStatus.inCalibrationCheckout.quantity}${t("times")} ${t("inCalibrationCheckout")}: ${data.fleetCalibrationStatus.inCalibrationCheckout.list.join(`, `)}
${data.fleetCalibrationStatus.inCalibration.quantity}${t("times")} ${t("inCalibration")}: ${data.fleetCalibrationStatus.inCalibration.list.join(`, `)}
${data.fleetCalibrationStatus.uncalibrated.quantity}${t("times")} ${t("uncalibrated")}: ${data.fleetCalibrationStatus.uncalibrated.list.join(`, `)}`;
    }

    const fleetMaintenanceStatusText = () => {
        return `${data.fleetMaintenanceStatus.maintenanceNotRequired.quantity}${t("times")} ${t("maintenanceNotRequired")}: ${data.fleetMaintenanceStatus.maintenanceNotRequired.list.join(`, `)}
${data.fleetMaintenanceStatus.needsNonBlockingMaintenance.quantity}${t("times")} ${t("needsNonBlockingMaintenance")}: ${data.fleetMaintenanceStatus.needsNonBlockingMaintenance.list.join(`, `)}
${data.fleetMaintenanceStatus.needsBlockingMaintenance.quantity}${t("times")} ${t("needsBlockingMaintenance")}: ${data.fleetMaintenanceStatus.needsBlockingMaintenance.list.join(`, `)}
${data.fleetMaintenanceStatus.inMaintenance.quantity}${t("times")} ${t("inMaintenance")}: ${data.fleetMaintenanceStatus.inMaintenance.list.join(`, `)}`;
    }

    const deploymentsTableHeaders = {
        name: t("name"),
        travelDates: t("travelDates"),
        location: t("location"),
        robots: t("robots"),
    }

    const processDeployments = (deployments) => {
        return deployments.map(deployment => {
            return {
                name: (<LinkWithParams baseUrl={routes.deployment.path} params={{id: deployment.id}}
                                       text={deployment.name} key={uniqueId()}/>),
                travelDates: `${convertToPrettyDate(deployment.travelStartDate)}-${convertToPrettyDate(deployment.travelEndDate)}`,
                location: deployment.location,
                robots: (() => {
                    const robots = deployment.robots.reduce((acc, e, index) => {
                        if (index > 0) {
                            acc.push(", ");
                        }
                        acc.push(<LinkWithParams baseUrl={routes.robot.path} params={{id: e.id}} text={e.name}
                                                 key={uniqueId()}/>);
                        return acc;
                    }, []);
                    return (
                        <div>
                            {deployment.robotsAssigned}/{deployment.robotsRequired}{!!deployment.robots.length && (<>&nbsp;({robots})</>)}
                        </div>
                    );
                })(),
            };
        });
    };

    const processHistoricalStatusData = (historicalStatusData) => {
        return Object.keys(historicalStatusData).map(date => {
            return {
                ...historicalStatusData[date],
                date: convertToPrettyDate(date)
            };
        })
    }

    const HistoricalStatusTooltip = ({active, payload, label}) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={tooltipStyles}>
                    <div className="fw-bold text-size-lg">{label}</div>
                    {payload.map((entry, index) => (
                        <div key={`historical-status-chart-tooltip-item-${index}`}>
                            {`${t(`api:robot_status_list.${entry.name}`)}: ${entry.value}`}
                        </div>
                    ))}
                </div>
            );
        }
        return null;
    };

    if (data === undefined) {
        return <FullPageLoading/>;
    }

    return (
        <>
            <PageTitle>{t("fleetOverview")}</PageTitle>
            <div className="d-flex flex-wrap">
                <div className="col-12 col-lg-6">
                    {!loaded ? (
                        <Card>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%"
                            }}>
                                <LoadingSpinner size={48}></LoadingSpinner>
                            </div>
                        </Card>
                    ) : (
                        <Card
                            color={activeRobots > twoWeekPeakDemand + 2 ? `var(--bs-success-bg-subtle)` : (activeRobots >= twoWeekPeakDemand ? `var(--bs-warning-bg-subtle)` : `var(--bs-danger-bg-subtle)`)}>
                            <div
                                className="fw-bold py-3"
                                style={{
                                    alignItems: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                    height: "100%",
                                    justifyContent: "center",
                                    textAlign: "center"
                                }}>
                                {activeRobots > twoWeekPeakDemand + 2 ? (
                                    <>
                                        <FontAwesomeIcon icon={faCheckCircle} size="2x"
                                                         style={{marginBottom: "8px"}}/>
                                        {t("fleetHealthGood")}
                                    </>
                                ) : (activeRobots >= twoWeekPeakDemand ? (
                                    <>
                                        <FontAwesomeIcon icon={faExclamationTriangle} size="2x"
                                                         style={{marginBottom: "8px"}}/>
                                        {t("fleetHealthRisk")}
                                    </>
                                ) : (
                                    <>
                                        <FontAwesomeIcon icon={faTimesCircle} size="2x"
                                                         style={{marginBottom: "8px"}}/>
                                        {t("fleetHealthPoor")}
                                    </>
                                ))}
                                <br/>
                                {`${t("activeFleet")}: ${activeRobots}.  ${t("peakTwoWeekDemand")}: ${twoWeekPeakDemand}.`}
                            </div>
                        </Card>
                    )}
                    <Card>
                        <div className="fw-bold text-size-lg">{t("fleetStatus")}</div>
                        <ProportionSummary
                            loaded={loaded}
                            quantity={data.fleetStatus.reserved.quantity}
                            quantityOf={t("reserved")}
                            list={data.fleetStatus.reserved.list}
                            proportion={data.fleetStatus.reserved.proportion}
                            color={stateColors.reserved}
                        />
                        <ProportionSummary
                            loaded={loaded}
                            quantity={data.fleetStatus.deployed.quantity}
                            quantityOf={t("deployed")}
                            list={data.fleetStatus.deployed.list}
                            proportion={data.fleetStatus.deployed.proportion}
                            color={stateColors.deployed}
                        />
                        <ProportionSummary
                            loaded={loaded}
                            quantity={data.fleetStatus.jobReady.quantity}
                            quantityOf={t("jobReady")}
                            list={data.fleetStatus.jobReady.list}
                            proportion={data.fleetStatus.jobReady.proportion}
                            color={stateColors.jobReady}
                        />
                        <ProportionSummary
                            loaded={loaded}
                            quantity={data.fleetStatus.developmentReady.quantity}
                            quantityOf={t("developmentReady")}
                            list={data.fleetStatus.developmentReady.list}
                            proportion={data.fleetStatus.developmentReady.proportion}
                            color={stateColors.developmentReady}
                        />
                        <ProportionSummary
                            loaded={loaded}
                            quantity={data.fleetStatus.inMaintenance.quantity}
                            quantityOf={t("inMaintenance")}
                            list={data.fleetStatus.inMaintenance.list}
                            proportion={data.fleetStatus.inMaintenance.proportion}
                            color={stateColors.inMaintenance}
                            marginBottom={false}
                        />
                        {!loaded ? (
                            <div className="mt-4">
                                <LoadingSpinner size={32}/>
                            </div>
                        ) : (
                            <div className="d-flex mt-4">
                                <CopyButton text={fleetStatusText()} size={24} className="pe-1"/>
                                <DownloadButton text={fleetStatusText()} size={24}/>
                            </div>
                        )}
                    </Card>
                    <Card>
                        <div className="fw-bold text-size-lg">{t("fleetCalibrationStatus")}</div>
                        <ProportionSummary
                            loaded={loaded}
                            quantity={data.fleetCalibrationStatus.calibrated.quantity}
                            quantityOf={t("calibrated")}
                            list={data.fleetCalibrationStatus.calibrated.list}
                            proportion={data.fleetCalibrationStatus.calibrated.proportion}
                            color={stateColors.calibrated}
                        />
                        <ProportionSummary
                            loaded={loaded}
                            quantity={data.fleetCalibrationStatus.checkInTesting.quantity}
                            quantityOf={t("checkInTesting")}
                            list={data.fleetCalibrationStatus.checkInTesting.list}
                            proportion={data.fleetCalibrationStatus.checkInTesting.proportion}
                            color={stateColors.checkInTesting}
                        />
                        <ProportionSummary
                            loaded={loaded}
                            quantity={data.fleetCalibrationStatus.inCalibrationCheckout.quantity}
                            quantityOf={t("inCalibrationCheckout")}
                            list={data.fleetCalibrationStatus.inCalibrationCheckout.list}
                            proportion={data.fleetCalibrationStatus.inCalibrationCheckout.proportion}
                            color={stateColors.inCalibrationCheckout}
                        />
                        <ProportionSummary
                            loaded={loaded}
                            quantity={data.fleetCalibrationStatus.inCalibration.quantity}
                            quantityOf={t("inCalibration")}
                            list={data.fleetCalibrationStatus.inCalibration.list}
                            proportion={data.fleetCalibrationStatus.inCalibration.proportion}
                            color={stateColors.inCalibration}
                        />
                        <ProportionSummary
                            loaded={loaded}
                            quantity={data.fleetCalibrationStatus.uncalibrated.quantity}
                            quantityOf={t("uncalibrated")}
                            list={data.fleetCalibrationStatus.uncalibrated.list}
                            proportion={data.fleetCalibrationStatus.uncalibrated.proportion}
                            color={stateColors.uncalibrated}
                        />
                        {!loaded ? (
                            <div className="mt-4">
                                <LoadingSpinner size={32}/>
                            </div>
                        ) : (
                            <div className="d-flex mt-4">
                                <CopyButton text={fleetCalibrationStatusText()} size={24} className="pe-1"/>
                                <DownloadButton text={fleetCalibrationStatusText()} size={24}/>
                            </div>
                        )}
                    </Card>
                    <Card>
                        <div className="fw-bold text-size-lg">{t("fleetMaintenanceStatus")}</div>
                        <ProportionSummary
                            loaded={loaded}
                            quantity={data.fleetMaintenanceStatus.maintenanceNotRequired.quantity}
                            quantityOf={t("maintenanceNotRequired")}
                            list={data.fleetMaintenanceStatus.maintenanceNotRequired.list}
                            proportion={data.fleetMaintenanceStatus.maintenanceNotRequired.proportion}
                            color={stateColors.maintenanceNotRequired}
                        />
                        <ProportionSummary
                            loaded={loaded}
                            quantity={data.fleetMaintenanceStatus.needsNonBlockingMaintenance.quantity}
                            quantityOf={t("needsNonBlockingMaintenance")}
                            list={data.fleetMaintenanceStatus.needsNonBlockingMaintenance.list}
                            proportion={data.fleetMaintenanceStatus.needsNonBlockingMaintenance.proportion}
                            color={stateColors.needsNonBlockingMaintenance}
                        />
                        <ProportionSummary
                            loaded={loaded}
                            quantity={data.fleetMaintenanceStatus.needsBlockingMaintenance.quantity}
                            quantityOf={t("needsBlockingMaintenance")}
                            list={data.fleetMaintenanceStatus.needsBlockingMaintenance.list}
                            proportion={data.fleetMaintenanceStatus.needsBlockingMaintenance.proportion}
                            color={stateColors.needsBlockingMaintenance}
                        />
                        <ProportionSummary
                            loaded={loaded}
                            quantity={data.fleetMaintenanceStatus.inMaintenance.quantity}
                            quantityOf={t("inMaintenance")}
                            list={data.fleetMaintenanceStatus.inMaintenance.list}
                            proportion={data.fleetMaintenanceStatus.inMaintenance.proportion}
                            color={stateColors.inMaintenance}
                            marginBottom={false}
                        />
                        {!loaded ? (
                            <div className="mt-4">
                                <LoadingSpinner size={32}/>
                            </div>
                        ) : (
                            <div className="d-flex mt-4">
                                <CopyButton text={fleetMaintenanceStatusText()} size={24} className="pe-1"/>
                                <DownloadButton text={fleetMaintenanceStatusText()} size={24}/>
                            </div>
                        )}
                    </Card>
                </div>
                <div className="col-12 col-lg-6">
                    <Card>
                        <div className="fw-bold text-size-lg">{t("thisWeek")}</div>
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("deployments")}:`}
                            value={data.thisWeek.deploymentsQuantity}
                            className="mt-2"
                        />
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("robots")}:`}
                            value={data.thisWeek.robotsQuantity}
                        />
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("staff")}:`}
                            value={data.thisWeek.staffQuantity}
                        />
                        <SearchableList
                            loaded={loaded}
                            title={t("deployments")}
                            columnNames={deploymentsTableHeaders}
                            data={processDeployments(data.thisWeek.deploymentsList)}
                            className="mt-4"
                        />
                    </Card>
                    <Card>
                        <div className="fw-bold text-size-lg">{t("nextWeek")}</div>
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("deployments")}:`}
                            value={data.nextWeek.deploymentsQuantity}
                            className="mt-2"
                        />
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("robots")}:`}
                            value={data.nextWeek.robotsQuantity}
                        />
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("staff")}:`}
                            value={data.nextWeek.staffQuantity}
                        />
                        <SearchableList
                            loaded={loaded}
                            title={t("deployments")}
                            columnNames={deploymentsTableHeaders}
                            data={processDeployments(data.nextWeek.deploymentsList)}
                            className={"mt-4"}
                        />
                    </Card>
                    <Card>
                        <div className="fw-bold text-size-lg">{t("twoWeeks")}</div>
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("deployments")}:`}
                            value={data.twoWeeks.deploymentsQuantity}
                            className="mt-2"
                        />
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("robots")}:`}
                            value={data.twoWeeks.robotsQuantity}
                        />
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("staff")}:`}
                            value={data.twoWeeks.staffQuantity}
                        />
                        <SearchableList
                            loaded={loaded}
                            title={t("deployments")}
                            columnNames={deploymentsTableHeaders}
                            data={processDeployments(data.twoWeeks.deploymentsList)}
                            className={"mt-4"}
                        />
                    </Card>
                </div>
            </div>
            <div className="d-flex flex-wrap">
                <PageTitle>{t("fleetHistory")}</PageTitle>
                <div className="col-12">
                    <Card>
                        <div className="fw-bold mb-3 text-size-lg">{t("historicalStatusChart")}</div>
                        {loaded ? (
                            <>
                                <div className="d-flex">
                                    <div>
                                        <AreaChart width={64} height={512} margin={{top: 8, right: 0, bottom: 24}}
                                                   data={processHistoricalStatusData(data.historicalStatusData)}>
                                            <YAxis
                                                width={64}
                                                domain={[0, 20]}
                                                tickCount={5}
                                                padding={{bottom: 30}}
                                                tick={{fontSize: 14, fontFamily: "Public Sans", fill: "var(--dark)"}}
                                                axisLine={{stroke: "var(--dark)", strokeOpacity: 1, strokeWidth: 3}}
                                                tickLine={{stroke: "var(--dark)", strokeOpacity: 1, strokeWidth: 1}}
                                                label={{
                                                    value: t("robots"),
                                                    angle: -90,
                                                    position: "insideLeft",
                                                    style: {
                                                        textAnchor: "middle",
                                                        fill: "var(--dark)",
                                                        fontSize: 16,
                                                        fontFamily: "Public Sans"
                                                    },
                                                    dx: 8
                                                }}
                                            />
                                        </AreaChart>
                                    </div>
                                    <div ref={historicalStatusChartContainerRef}
                                         className="flex-grow-1 overflow-x-scroll">
                                        <AreaChart width={4096} height={512}
                                                   margin={{top: 8, right: 8, bottom: 24, left: -1}}
                                                   data={processHistoricalStatusData(data.historicalStatusData)}>
                                            <YAxis
                                                width={64}
                                                domain={[0, 20]}
                                                tickCount={5}
                                                hide={true}
                                            />
                                            <XAxis
                                                dataKey="date"
                                                angle={-60}
                                                textAnchor="end"
                                                dx={-2}
                                                dy={2}
                                                tick={{fontSize: 14, fontFamily: "Public Sans", fill: "var(--dark)"}}
                                                axisLine={{stroke: "var(--dark)", strokeOpacity: 1, strokeWidth: 3}}
                                                tickLine={{stroke: "var(--dark)", strokeOpacity: 1, strokeWidth: 1}}
                                                interval={0}
                                            />
                                            <RechartsTooltip
                                                content={<HistoricalStatusTooltip/>}
                                                cursor={{stroke: "var(--dark)", strokeWidth: 1}}
                                            />
                                            <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false}/>
                                            <Area
                                                dataKey="inMaintenance"
                                                stackId="1"
                                                stroke={stateColors.inMaintenance}
                                                strokeWidth={2}
                                                fill={stateColors.inMaintenanceChart}
                                                activeDot={{
                                                    r: 4,
                                                    fill: stateColors.inMaintenance,
                                                    strokeWidth: 2,
                                                    stroke: "var(--white)",
                                                    opacity: 1,
                                                    strokeOpacity: 1
                                                }}
                                            />
                                            <Area
                                                dataKey="developmentReady"
                                                stackId="1"
                                                stroke={stateColors.developmentReady}
                                                strokeWidth={2}
                                                fill={stateColors.developmentReadyChart}
                                                activeDot={{
                                                    r: 4,
                                                    fill: stateColors.developmentReady,
                                                    strokeWidth: 2,
                                                    stroke: "var(--white)",
                                                    opacity: 1,
                                                    strokeOpacity: 1
                                                }}
                                            />
                                            <Area
                                                dataKey="jobReady"
                                                stackId="1"
                                                stroke={stateColors.jobReady}
                                                strokeWidth={2}
                                                fill={stateColors.jobReadyChart}
                                                activeDot={{
                                                    r: 4,
                                                    fill: stateColors.jobReady,
                                                    strokeWidth: 2,
                                                    stroke: "var(--white)",
                                                    opacity: 1,
                                                    strokeOpacity: 1
                                                }}
                                            />
                                            <Area
                                                dataKey="deployed"
                                                stackId="1"
                                                stroke={stateColors.deployed}
                                                strokeWidth={2}
                                                fill={stateColors.deployedChart}
                                                activeDot={{
                                                    r: 4,
                                                    fill: stateColors.deployed,
                                                    strokeWidth: 2,
                                                    stroke: "var(--white)",
                                                    opacity: 1,
                                                    strokeOpacity: 1
                                                }}
                                            />
                                            <Area
                                                dataKey="reserved"
                                                stackId="1"
                                                stroke={stateColors.reserved}
                                                strokeWidth={2}
                                                fill={stateColors.reservedChart}
                                                activeDot={{
                                                    r: 4,
                                                    fill: stateColors.reserved,
                                                    strokeWidth: 2,
                                                    stroke: "var(--white)",
                                                    opacity: 1,
                                                    strokeOpacity: 1
                                                }}
                                            />
                                        </AreaChart>
                                    </div>
                                </div>
                                <div className="align-items-center d-flex flex-wrap mt-1">
                                    <div className="align-items-center d-flex flex-nowrap">
                                        <FontAwesomeIcon icon={faCircle}
                                                         style={{color: stateColors.reservedChart}}/>&nbsp;{t("reserved")}
                                        <div className="me-4"></div>
                                        <FontAwesomeIcon icon={faCircle}
                                                         style={{color: stateColors.deployedChart}}/>&nbsp;{t("deployed")}
                                        <div className="me-4"></div>
                                    </div>
                                    <div className="align-items-center d-flex flex-nowrap">
                                        <FontAwesomeIcon icon={faCircle}
                                                         style={{color: stateColors.jobReadyChart}}/>&nbsp;{t("jobReady")}
                                        <div className="me-4"></div>
                                        <FontAwesomeIcon icon={faCircle}
                                                         style={{color: stateColors.developmentReadyChart}}/>&nbsp;{t("developmentReady")}
                                        <div className="me-4"></div>
                                    </div>
                                    <div className="align-items-center d-flex flex-nowrap">
                                        <FontAwesomeIcon icon={faCircle}
                                                         style={{color: stateColors.inMaintenanceChart}}/>&nbsp;{t("inMaintenance")}
                                        <div className="me-4"></div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <LoadingSpinner size={48}></LoadingSpinner>
                        )}
                    </Card>
                </div>
            </div>
        </>
    )
}

export { Fleet };
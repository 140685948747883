import axios from "axios";
import PropTypes from "prop-types";
import { t } from "i18next";
import Ajv from "ajv/dist/2020";
import { getToken, redirectToLogin } from "services/auth";

const apiEndpoints = {
    home: `/portal/v1/home`,
    schedule: `/portal/v1/schedule`,
    fleet: `/portal/v1/fleet`,
    robots: `/portal/v1/robots`,
    robot: `/portal/v1/robots/`,
    robotNetwork: `/portal/v1/robots/network/`,
    staffList: `/portal/v1/staff-list`,
    mobileProviderList: `/portal/v1/options/mobile-provider`,
    robotGenerationList: `/portal/v1/options/robot-generation`,
    newRobotReservation: {
        constructUrl: () => `/portal/v1/robot-reservations`,
        schemaPath: `/portal/v1/robot-reservations/_`
    },
    robotMaintenanceStatusList: `/portal/v1/options/robot-maintenance-status`,
    robotCalibrationStatusList: `/portal/v1/options/robot-calibration-status`,
    robotMaintenanceHistory: `/portal/v1/robots/maintenance/`,
    robotCalibrationHistory: `/portal/v1/robots/calibration/`,
    updateRobotMaintenanceStatus: `/portal/v1/robots/maintenance`,
    updateRobotCalibrationStatus: `/portal/v1/robots/calibration`,
    operations: `/portal/v1/operations`,
    operators: `/portal/v1/operators`,
    allStaff: `/portal/v1/all-staff`,
    operator: `/portal/v1/operators/`,
    deployments: `/portal/v1/deployments`,
    allDeployments: `/portal/v1/all-deployments`,
    deployment: `/portal/v1/deployments/`,
    deploymentSiteOptions: {
        constructUrl: (deploymentId) => `/portal/v1/deployments/${deploymentId}/site-options`,
        schemaPath: `/portal/v1/deployments/_/site-options`,
    },
    linkDeploymentAndSite: {
        constructUrl: (deploymentId, siteId) => `/portal/v1/deployments/${deploymentId}/site/${siteId}`,
        schemaPath: `/portal/v1/deployments/_/site/_`,
    },
    deploymentConfidenceLevelList: `/portal/v1/options/deployment-confidence-level`,
    assignmentOptions: `/portal/v1/assignment-options/`,
    assignment: `/portal/v1/assignments/`,
    newAssignment: `/portal/v1/assignments`,
    editAssignment: `/portal/v1/assignments/`,
    sites: `/portal/v1/sites`,
    site: {
        constructUrl: (siteId) => `/portal/v1/sites/${siteId}`,
        schemaPath: `/portal/v1/sites/_`,
    },
    siteDealOptions: {
        constructUrl: (siteId) => `/portal/v1/sites/${siteId}/deal-options`,
        schemaPath: `/portal/v1/sites/_/deal-options`,
    },
    siteDeploymentOptions: {
        constructUrl: (siteId) => `/portal/v1/sites/${siteId}/deployment-options`,
        schemaPath: `/portal/v1/sites/_/deployment-options`,
    },
    newFilestoreOptions: {
        constructUrl: (siteId) => `/portal/v1/sites/${siteId}/filestore-options`,
        schemaPath: `/portal/v1/sites/_/filestore-options`,
    },
    newFilestore: {
        constructUrl: () => `/portal/v1/filestores`,
        schemaPath: `/portal/v1/filestores`,
    },
    filestoreStorage: {
        constructUrl: (filestoreId, path) =>  `/portal/v1/filestores/${filestoreId}/storage/${path}`,
        schemaPath: `/portal/v1/filestores/_/storage/_`
    },
    filestoreDownload: {
        constructUrl: (filestoreId, path) =>  `/portal/v1/filestores/${filestoreId}/download/${path}`,
        schemaPath: `/portal/v1/filestores/_/download/_`
    },
    deals: `/portal/v1/deals`,
    dealSiteOptions: {
        constructUrl: (dealId) => `/portal/v1/deals/${dealId}/site-options`,
        schemaPath: `/portal/v1/deals/_/site-options`,
    },
    linkDealAndSite: {
        constructUrl: (dealId, siteId) => `/portal/v1/deals/${dealId}/site/${siteId}`,
        schemaPath: `/portal/v1/deals/_/site/_`,
    },
    refreshDeals: `/portal/v1/refresh-deals`,
    deal: `/portal/v1/deals/`,
    reports: `/portal/v1/reports`,
    generateUtilizationReport: `/portal/v1/generate-report/utilization`
};

const apiRequest = async (setResponse, setError, requestMethod, endpoint, endpointParams = ``, payload = {}) => {
    try {
        const requestUrl = `${process.env.REACT_APP_API_URL}${endpoint}${endpointParams}`;
        console.log(requestUrl);
        let response;
        switch (requestMethod.toLowerCase()) {
            case `get`:
                const queryParams = new URLSearchParams(payload).toString();
                response = await axios.get(`${requestUrl}${queryParams ? `?${queryParams}` : ``}`, {
                    headers: {
                        "Authorization": `Bearer ${getToken()}`
                    },
                    withCredentials: true,
                });
                break;
            case `post`:
                response = await axios.post(requestUrl, payload, {
                    headers: {
                        "Authorization": `Bearer ${getToken()}`
                    },
                    withCredentials: true,
                });
                break;
            case `put`:
                response = await axios.put(requestUrl, payload, {
                    headers: {
                        "Authorization": `Bearer ${getToken()}`
                    },
                    withCredentials: true,
                });
                break;
            case `delete`:
                response = await axios.delete(requestUrl, {
                    headers: {
                        "Authorization": `Bearer ${getToken()}`
                    },
                    data: payload,
                    withCredentials: true,
                });
                break;
            default:
                console.error(`Invalid request method: ${requestMethod}`);
        }
        if (response.data && response.data.code >= 200 && response.data.code < 300) {
            const validApiResponse = await validateApiResponse(requestMethod, endpoint, response.data.data);
            if (validApiResponse) {
                setResponse(response.data.data);
                return true;
            } else {
                setError(t("invalidApiResponse"));
                return false;
            }
        } else {
            setError(`${t("errorFetchingData")}${statusCode ? ` (${statusCode})` : ``}`);
            return false;
        }
    } catch (error) {
        if (error.statusCode === 401) {
            redirectToLogin();
        } else if (error.statusCode === 403) {
            setError(t("unauthorized"));
            return false;
        } else {
            console.log(error)
            const statusCode = error.statusCode;
            setError(`${t("errorFetchingData")}${statusCode ? ` (${statusCode})` : ``}`);
            return false;
        }
    }
};

apiRequest.propTypes = {
    setResponse: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    endpoint: PropTypes.string.isRequired,
    requestType: PropTypes.oneOf(["get", "post", "put", "delete"]).isRequired,
    payload: PropTypes.object,
    endpointParams: PropTypes.string,
};

const apiFileRequest = async (setError, requestMethod, endpoint, endpointParams = ``, payload = {}) => {
    try {
        const requestUrl = `${process.env.REACT_APP_API_URL}${endpoint}${endpointParams}`;
        let response;
        const axiosConfig = {
            headers: {
                "Authorization": `Bearer ${getToken()}`
            },
            withCredentials: true,
            responseType: `blob`,
            maxRedirects: 0
        };
        switch (requestMethod.toLowerCase()) {
            case `get`:
                const queryParams = new URLSearchParams(payload).toString();
                response = await axios.get(`${requestUrl}${queryParams ? `?${queryParams}` : ``}`, axiosConfig);
                break;
            case `post`:
                response = await axios.post(requestUrl, payload, axiosConfig);
                break;
            case `put`:
                response = await axios.put(requestUrl, payload, axiosConfig);
                break;
            case `delete`:
                response = await axios.delete(requestUrl, { ...axiosConfig, data: payload });
                break;
            default:
                console.error(`Invalid request method: ${requestMethod}`);
                return false;
        }
        const contentDisposition = response.headers["content-disposition"] || response.headers["Content-Disposition"];
        if (contentDisposition && contentDisposition.includes("attachment")) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement(`a`);
            link.href = url;
            const filename = contentDisposition.split("filename=")[1].split(";")[0].replace(/"/g, ``);
            link.setAttribute(`download`, filename);
            link.style.position = "absolute";
            link.style.left = "-9999px";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            return true;
        } else {
            setError(`${t("errorFetchingFile")}`);
            return false;
        }
    } catch (error) {
        if (error.statusCode === 401) {
            redirectToLogin();
        } else if (error.statusCode === 403) {
            setError(t("unauthorized"));
            return false;
        } else {
            const statusCode = error.statusCode;
            setError(`${t("errorFetchingData")}${statusCode ? ` (${statusCode})` : ``}`);
            return false;
        }
    }
};

apiRequest.propTypes = {
    setError: PropTypes.func.isRequired,
    endpoint: PropTypes.string.isRequired,
    requestType: PropTypes.oneOf(["get", "post", "put", "delete"]).isRequired,
    payload: PropTypes.object,
    endpointParams: PropTypes.string,
};

const validateApiResponse = async (requestMethod, schemaPath, apiResponse) => {
    const ajv = new Ajv({strict: false});
    try {
        const schema = await loadSchema(requestMethod, schemaPath);
        const validate = ajv.compile(schema);
        const valid = validate(apiResponse);
        if (!valid) console.error(validate.errors);
        return valid;
    } catch (error) {
        console.error(`Proceeding without validation due to error loading schema: ${error}`);
        return true;
    }
};

validateApiResponse.propTypes = {
    schemaPath: PropTypes.string.isRequired,
    apiResponse: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

const createEmptyApiResponse = async (requestMethod, schemaPath) => {
    try {
        const schema = await loadSchema(requestMethod, schemaPath);
        return createEmptyObject(schema);
    } catch (error) {
        console.error(`Error creating empty API response: ${error}`);
        return null;
    }
};

createEmptyApiResponse.propTypes = {
    schemaPath: PropTypes.string.isRequired,
};

const loadSchema = async (requestMethod, schemaPath) => {
    const schemasBaseUrl = `${process.env.PUBLIC_URL}/schemas/response`;
    if (schemaPath.endsWith(`/`)) schemaPath = `${schemaPath}index`;
    const schemaUrl = `${schemasBaseUrl}/${requestMethod}${schemaPath}.json`;
    const response = await fetch(schemaUrl);
    if (!response.ok) throw new Error(`Failed to fetch schema from ${schemaUrl}`);
    return await response.json();
};

loadSchema.propTypes = {
    schemaPath: PropTypes.string.isRequired,
};

const createEmptyObject = (schema, definitions = schema.definitions) => {
    if (schema.$ref) {
        const refSchema = schema.$ref.replace(`#/definitions/`, ``);
        return createEmptyObject(definitions[refSchema], definitions);
    }
    if (schema.type === `object`) {
        const obj = {};
        if (schema.properties) {
            for (const key in schema.properties) {
                obj[key] = createEmptyObject(schema.properties[key], definitions);
            }
        }
        return obj;
    }
    if (schema.type === `array`) return [];
    if (schema.type === `string`) return ``;
    if (schema.type === `number`) return 0;
    if (schema.type === "integer") return 0;
    return null;
};

createEmptyObject.propTypes = {
    schema: PropTypes.object.isRequired,
    definitions: PropTypes.object,
};

const requestTypes = {
    DELETE: "delete",
    GET: "get",
    POST: "post",
    PUT: "put",
}

export { apiRequest, apiFileRequest, createEmptyApiResponse, apiEndpoints };

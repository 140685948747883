import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Calendar,
    Card,
    FullPageLoading,
    LinkWithParams,
    LoadingSpinner,
    PageBackButton,
    PageTitle,
    SearchableList,
    StaffCalendarLegend,
    styleCalendarData,
    TitleValueLine
} from "components/utils/ui";
import { apiEndpoints, apiRequest, createEmptyApiResponse } from "services/api";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "helpers/routes";
import { convertToPrettyDate, dateObjectToDateString, getLastSundayDateObject } from "helpers/date";
import { useHotkeys } from "react-hotkeys-hook";
import { uniqueId } from "helpers/random";

const Operator = () => {
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);

    const lastSunday = dateObjectToDateString(getLastSundayDateObject());

    useHotkeys("esc", () => navigate(routes.operators.path));

    useEffect(() => {
        const getDataApiEndpoint = apiEndpoints.operator;
        createEmptyApiResponse(`get`, getDataApiEndpoint).then(emptyApiResponse => {
            if (!data && emptyApiResponse) setData(emptyApiResponse);
        });
        apiRequest(setData, setError, `get`, getDataApiEndpoint, id).then(apiRequestSuccess => {
            if (apiRequestSuccess) setLoaded(true);
        })
    }, []);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const ptoAndUftTableHeaders = {
        dates: t("dates"),
    }

    const processPtoAndUft = (ptoAndUft) => {
        return ptoAndUft.map(dates => {
            return {
                dates: `${convertToPrettyDate(dates.startDate)}-${convertToPrettyDate(dates.endDate)}`,
            };
        });
    };

    const processCalendarData = (calendarData) => {
        return calendarData.map(e => styleCalendarData(e));
    };

    const assignedRobotsTableHeaders = {
        robot: t("robot")
    }

    const processAssignedRobots = (robots) => {
        return robots.map(robot => {
            return {
                robot: (<LinkWithParams baseUrl={routes.robot.path} params={{id: robot.id}} text={robot.name}
                                        key={uniqueId()}/>),
            }
        });
    }

    const assignmentsTableHeaders = {
        deployment: t("name"),
        travelDates: t("travelDates")

    }

    const processAssignments = (assignments) => {
        return assignments.map(assignment => {
            return {
                deployment: (<LinkWithParams baseUrl={routes.deployment.path} params={{id: assignment.deployment.id}}
                                             text={assignment.deployment.name}/>),
                travelDates: `${convertToPrettyDate(assignment.travelStartDate)}-${convertToPrettyDate(assignment.travelEndDate)}`,
            };
        });
    };

    if (data === undefined) {
        return <FullPageLoading/>;
    }

    return (
        <>
            <PageTitle className="align-items-center d-flex">
                <div>
                    <PageBackButton className="mb-1" baseUrl={routes.operators.path}>{t("operators")}</PageBackButton>
                    {loaded ? (
                        <div className="align-items-center d-flex">
                            {data.summary.name}
                            {data.summary.status === "terminated" && (
                                <div className="badge badge-accent ms-2">{t("TERMINATED")}</div>
                            )}
                        </div>
                    ) : (
                        <LoadingSpinner size={46}/>
                    )}
                </div>
                <div className="flex-grow-1"></div>
            </PageTitle>
            <div className="d-flex flex-wrap">
                <div className="col-12 col-lg-6">
                    <Card>
                        <div className="fw-bold text-size-lg">{t("summary")}</div>
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("name")}:`}
                            value={data.summary.name}
                            className="mt-2"
                        />
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("jobTitle")}:`}
                            value={data.summary.jobTitle}
                        />
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("operator")}:`}
                            value={data.summary.foreman ? t("yes") : t("no")}
                        />
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("foreman")}:`}
                            value={data.summary.foreman ? t("yes") : t("no")}
                        />
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("status")}:`}
                            value={t(`api:operator_status_list.${data.summary.status}`)}
                        />
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("email")}:`}
                            value={<a className="text-color-accent clickable"
                                      href={`mailto:${data.summary.email}`}>{data.summary.email}</a>}
                        />
                    </Card>
                    <Card>
                        <div className="fw-bold text-size-lg mb-3">{t("schedule")}</div>
                        <Calendar loaded={loaded} startDate={data.schedule.startDate} endDate={data.schedule.endDate}
                                  rows={data.schedule.staff}
                                  events={processCalendarData(data.schedule.staffSchedule)}
                                  initialDate={lastSunday}/>
                        <StaffCalendarLegend/>
                    </Card>
                    <Card>
                        <SearchableList
                            loaded={loaded}
                            title={t("upcomingPto")}
                            columnNames={ptoAndUftTableHeaders}
                            data={processPtoAndUft(data.pto)}
                        />
                    </Card>
                    <Card>
                        <SearchableList
                            loaded={loaded}
                            title={t("upcomingUft")}
                            columnNames={ptoAndUftTableHeaders}
                            data={processPtoAndUft(data.uft)}
                        />
                    </Card>
                </div>
                <div className="col-12 col-lg-6">
                    <Card>
                        <SearchableList
                            loaded={loaded}
                            title={t("assignedRobots")}
                            columnNames={assignedRobotsTableHeaders}
                            data={processAssignedRobots(data.assignedRobots)}
                        />
                    </Card>
                    <Card>
                        <SearchableList
                            loaded={loaded}
                            title={t("upcomingDeployments")}
                            columnNames={assignmentsTableHeaders}
                            data={processAssignments(data.upcomingDeployments)}
                        />
                    </Card>
                    <Card>
                        <SearchableList
                            loaded={loaded}
                            title={t("pastDeployments")}
                            columnNames={assignmentsTableHeaders}
                            data={processAssignments(data.pastDeployments)}
                        />
                    </Card>
                </div>
            </div>
        </>
    )
}

export { Operator };
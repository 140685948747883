import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, LoadingSpinner, PageBackButton, PageTitle, TitleValueInput, tooltipStyles } from "components/utils/ui";
import { apiEndpoints, apiRequest } from "services/api";
import { routes, routeWithParams } from "helpers/routes";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";

const NewSite = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const passedState = location.state || {};

    const [name, setName] = useState(passedState.name ? passedState.name : "");
    const [citizenshipRequirement, setCitizenshipRequirement] = useState(passedState.citizenshipRequirement ? passedState.citizenshipRequirement : "");
    const [notes, setNotes] = useState(passedState.notes ? passedState.notes : "");
    const [address, setAddress] = useState(passedState.address ? passedState.address : "");
    const [address2, setAddress2] = useState(passedState.address2 ? passedState.address2 : "");
    const [city, setCity] = useState(passedState.city ? passedState.city : "");
    const [state, setState] = useState(passedState.state ? passedState.state : "");
    const [zip, setZip] = useState(passedState.zip ? passedState.zip : "");
    const [country, setCountry] = useState(passedState.country ? passedState.country : "");

    const [isCreatingSite, setIsCreatingSite] = useState(false);
    const [data, setData] = useState(undefined);
    const [error, setError] = useState(undefined);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const createSite = () => {
        if (!name) {
            window.alert(t("siteNameRequired"));
            return;
        }
        setIsCreatingSite(true);
        apiRequest(
            setData,
            setError,
            "post",
            apiEndpoints.sites,
            "",
            {
                name: name,
                citizenshipRequirement: citizenshipRequirement,
                notes: notes,
                address: address,
                address2: address2,
                city: city,
                state: state,
                zip: zip,
                country: country,
            }
        ).then(apiRequestSuccess => {
            if (!apiRequestSuccess) alert(t("errorCreatingSite"));
            setIsCreatingSite(false);
        });
    }

    useEffect(() => {
        if (data === undefined || !data.siteId) return;
        navigate(routeWithParams(routes.site.path, {id: data.siteId}));
    }, [data]);

    return (
        <>
            <PageTitle className="align-items-center d-flex">
                <div>
                    <PageBackButton className="mb-1"
                                    baseUrl={routes.sites.path}>{t("sites")}</PageBackButton>
                    {t("newSite")}
                </div>
                <div className="flex-grow-1"></div>
            </PageTitle>
            <div className="d-flex flex-wrap">
                <div className="col-12">
                    <Card>
                        <div className="fw-bold text-size-lg">{t("summary")}</div>
                        <div className="d-flex mt-2">
                            <TitleValueInput
                                title={`${t("name")}:`}
                                className="flex-grow-1"
                                value={name}
                                setValue={setName}
                            />
                            {!name && (
                                <>
                                    <FontAwesomeIcon icon={faExclamationCircle} className="text-color-accent"
                                                     data-tooltip-id="name-required-tooltip"/>
                                    <Tooltip
                                        id={`name-required-tooltip`}
                                        place="left"
                                        content={t("siteNameRequired")}
                                        style={tooltipStyles}
                                    />
                                </>
                            )}
                        </div>
                        <TitleValueInput
                            title={`${t("citizenshipRequirement")}:`}
                            value={citizenshipRequirement}
                            setValue={setCitizenshipRequirement}
                        />
                        <TitleValueInput
                            title={`${t("notes")}:`}
                            value={notes}
                            setValue={setNotes}
                        />
                    </Card>
                    <Card>
                        <div className="fw-bold text-size-lg">{t("location")}</div>
                        <TitleValueInput
                            title={`${t("address")}:`}
                            className="mt-2"
                            value={address}
                            setValue={setAddress}
                        />
                        <TitleValueInput
                            title={`${t("address2")}:`}
                            value={address2}
                            setValue={setAddress2}
                        />
                        <TitleValueInput
                            title={`${t("city")}:`}
                            value={city}
                            setValue={setCity}
                        />
                        <TitleValueInput
                            title={`${t("state")}:`}
                            value={state}
                            setValue={setState}
                        />
                        <TitleValueInput
                            title={`${t("zip")}:`}
                            value={zip}
                            setValue={setZip}
                        />
                        <TitleValueInput
                            title={`${t("country")}:`}
                            value={country}
                            setValue={setCountry}
                        />
                    </Card>
                </div>
                <div className="col-12 my-2 my-lg-3 d-flex justify-content-center">
                    {isCreatingSite ? (
                        <LoadingSpinner size={38}/>
                    ) : (
                        <>
                            <div className="col-6 text-end">
                                <button type="button" className="btn bg-accent me-3 text-white"
                                        onClick={() => createSite()} disabled={!name}>
                                    {t("save")}
                                </button>
                            </div>
                            <div className="col-6">
                                <button type="button" className="btn bg-med ms-3 text-white" onClick={() => {
                                    navigate(routes.sites.path);
                                }}>
                                    {t("cancel")}
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export { NewSite };

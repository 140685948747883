import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Card,
    ConfidenceLevelSelector,
    LoadingSpinner,
    PageBackButton,
    PageTitle,
    DealSelector,
    TitleValueInput,
    tooltipStyles
} from "components/utils/ui";
import { dateObjectToDateString } from "helpers/date";
import { apiEndpoints, apiRequest } from "services/api";
import { routes, routeWithParams } from "helpers/routes";
import { useLocation, useNavigate } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";

const NewDeployment = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const passedState = location.state || {};

    const [name, setName] = useState(passedState.name ? passedState.name : "");
    const [deal, setDeal] = useState(passedState.deal ? passedState.deal : null);
    const [confidenceLevel, setConfidenceLevel] = useState(passedState.confidenceLevel ? passedState.confidenceLevel : null);
    const [address, setAddress] = useState(passedState.address ? passedState.address : "");
    const [city, setCity] = useState(passedState.city ? passedState.city : "");
    const [state, setState] = useState(passedState.state ? passedState.state : "");
    const [zip, setZip] = useState(passedState.zip ? passedState.zip : "");
    const [country, setCountry] = useState(passedState.country ? passedState.country : "");
    const [travelStartDate, setTravelStartDate] = useState(passedState.travelStartDate ? new Date(passedState.travelStartDate + "T00:00:00") : new Date());
    const [startDate, setStartDate] = useState(passedState.startDate ? new Date(passedState.startDate + "T00:00:00") : new Date());
    const [endDate, setEndDate] = useState(passedState.endDate ? new Date(passedState.endDate + "T00:00:00") : new Date());
    const [travelEndDate, setTravelEndDate] = useState(passedState.travelEndDate ? new Date(passedState.travelEndDate + "T00:00:00") : new Date());
    const [staff, setStaff] = useState(passedState.staff ? passedState.staff : 0);
    const [calibratedRobots, setCalibratedRobots] = useState(passedState.calibratedRobots ? passedState.calibratedRobots : 0);
    const [uncalibratedRobots, setUncalibratedRobots] = useState(passedState.uncalibratedRobots ? passedState.uncalibratedRobots : 0);
    const [targets, setTargets] = useState(passedState.targets ? passedState.targets : 0);

    const [isCreatingDeployment, setIsCreatingDeployment] = useState(false);
    const [data, setData] = useState(undefined);
    const [error, setError] = useState(undefined);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    useEffect(() => {
        if (travelStartDate > startDate) setStartDate(travelStartDate);
    }, [travelStartDate]);
    useEffect(() => {
        if (startDate > endDate) setEndDate(startDate);
        if (startDate < travelStartDate) setTravelStartDate(startDate);
    }, [startDate]);
    useEffect(() => {
        if (endDate > travelEndDate) setTravelEndDate(endDate);
        if (endDate < startDate) setStartDate(endDate)
    }, [endDate]);
    useEffect(() => {
        if (travelEndDate < endDate) setEndDate(travelEndDate);
    }, [travelEndDate]);

    const handleSelect = (ranges) => {
        if (ranges.travelDates) {
            const newTravelStartDate = ranges.travelDates.startDate;
            const newTravelEndDate = ranges.travelDates.endDate;
            setTravelStartDate(newTravelStartDate);
            setTravelEndDate(newTravelEndDate);
        }
        if (ranges.dates) {
            const newStartDate = ranges.dates.startDate;
            const newEndDate = ranges.dates.endDate;
            setStartDate(newStartDate);
            setEndDate(newEndDate);
        }
    };

    const ranges = [
        {
            startDate: travelStartDate,
            endDate: travelEndDate,
            key: "travelDates",
            color: "#637378",
            autoFocus: true
        },
        {
            startDate: startDate,
            endDate: endDate,
            key: "dates",
            color: "#f25c07",
            autoFocus: true
        },
    ];

    const createDeployment = () => {
        if (!name) {
            window.alert(t("deploymentNameRequired"));
            return;
        }
        if (!confidenceLevel) {
            window.alert(t("deploymentConfidenceLevelRequired"));
            return;
        }
        setIsCreatingDeployment(true);
        apiRequest(
            setData,
            setError,
            "post",
            apiEndpoints.deployments,
            "",
            {
                name: name,
                address: address,
                city: city,
                state: state,
                zip: zip,
                country: country,
                travelStartDate: dateObjectToDateString(travelStartDate),
                startDate: dateObjectToDateString(startDate),
                endDate: dateObjectToDateString(endDate),
                travelEndDate: dateObjectToDateString(travelEndDate),
                confidenceLevel: confidenceLevel,
                numStaff: staff,
                numCalibratedRobots: calibratedRobots,
                numUncalibratedRobots: uncalibratedRobots,
                numTargets: targets,
                hubspotProject: deal
            }
        ).then(apiRequestSuccess => {
            if (!apiRequestSuccess) alert(t("errorCreatingDeployment"));
            setIsCreatingDeployment(false);
        });
    }

    useEffect(() => {
        if (data === undefined || !data.deploymentId) return;
        navigate(routeWithParams(routes.deployment.path, {id: data.deploymentId}));
    }, [data]);

    return (
        <>
            <PageTitle className="align-items-center d-flex">
                <div>
                    <PageBackButton className="mb-1"
                                    baseUrl={routes.deployments.path}>{t("deployments")}</PageBackButton>
                    {t("newDeployment")}
                </div>
                <div className="flex-grow-1"></div>
            </PageTitle>
            <div className="d-flex flex-wrap">
                <div className="col-12 col-lg-6">
                    <Card>
                        <div className="fw-bold text-size-lg">{t("summary")}</div>
                        <div className="d-flex mt-2">
                            <TitleValueInput
                                title={`${t("name")}:`}
                                className="flex-grow-1"
                                value={name}
                                setValue={setName}
                            />
                            {!name && (
                                <>
                                    <FontAwesomeIcon icon={faExclamationCircle} className="text-color-accent"
                                                     data-tooltip-id="name-required-tooltip"/>
                                    <Tooltip
                                        id={`name-required-tooltip`}
                                        place="left"
                                        content={t("deploymentNameRequired")}
                                        style={tooltipStyles}
                                    />
                                </>
                            )}
                        </div>
                        <div className="d-flex">
                            <div className="fw-bold me-1">{`${t("deal")}:`}</div>
                            <div className="flex-grow-1 text-color-med">
                                <DealSelector loaded={true} selectedDeal={deal}
                                              setSelectedDeal={setDeal}/>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="fw-bold me-1">{`${t("confidenceLevel")}:`}</div>
                            <div className="flex-grow-1 text-color-med">
                                <ConfidenceLevelSelector loaded={true} confidenceLevel={confidenceLevel}
                                                         setConfidenceLevel={setConfidenceLevel}
                                                         selectByDefault={true}/>
                            </div>
                        </div>
                    </Card>
                    <Card>
                        <div className="fw-bold text-size-lg">{t("location")}</div>
                        <TitleValueInput
                            title={`${t("address")}:`}
                            className="mt-2"
                            value={address}
                            setValue={setAddress}
                        />
                        <TitleValueInput
                            title={`${t("city")}:`}
                            value={city}
                            setValue={setCity}
                        />
                        <TitleValueInput
                            title={`${t("state")}:`}
                            value={state}
                            setValue={setState}
                        />
                        <TitleValueInput
                            title={`${t("zip")}:`}
                            value={zip}
                            setValue={setZip}
                        />
                        <TitleValueInput
                            title={`${t("country")}:`}
                            value={country}
                            setValue={setCountry}
                        />
                    </Card>
                    <Card>
                        <div className="fw-bold text-size-lg">{t("requirements")}</div>
                        <TitleValueInput
                            title={`${t("staff")}:`}
                            value={staff}
                            setValue={setStaff}
                            type="number"
                            inputMode="numeric"
                            inputAttributes={{min: 0}}
                            className="mt-2"
                        />
                        <TitleValueInput
                            value={calibratedRobots}
                            setValue={setCalibratedRobots}
                            title={`${t("calibratedRobots")}:`}
                            type="number"
                            inputMode="numeric"
                            inputAttributes={{min: 0}}
                        />
                        <TitleValueInput
                            title={`${t("uncalibratedRobots")}:`}
                            value={uncalibratedRobots}
                            setValue={setUncalibratedRobots}
                            type="number"
                            inputMode="numeric"
                            inputAttributes={{min: 0}}
                        />
                        <TitleValueInput
                            title={`${t("targets")}:`}
                            value={targets}
                            setValue={setTargets}
                            type="number"
                            inputMode="numeric"
                            inputAttributes={{min: 0}}
                        />
                    </Card>
                </div>
                <div className="d-flex flex-column col-12 col-lg-6">
                    <Card className="flex-grow-1">
                        <div className="text-center">
                            <div className="fw-bold text-size-lg my-2">{t("dates")}</div>
                            <DateRangePicker
                                onChange={handleSelect}
                                ranges={ranges}
                                shownDate={passedState.travelStartDate ? new Date(passedState.travelStartDate + "T00:00:00") : new Date()}
                                staticRanges={[]}
                                inputRanges={[]}
                                retainEndDateOnFirstSelection={true}
                            />
                            <div className="align-items-center d-flex justify-content-center mb-2 w-100">
                                <FontAwesomeIcon icon={faCircle}
                                                 className="text-color-med"/>&nbsp;{t("traveling")}
                                <FontAwesomeIcon icon={faCircle}
                                                 className="text-color-accent ps-4"/>&nbsp;{t("deployed")}
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="col-12 my-2 my-lg-3 d-flex justify-content-center">
                    {isCreatingDeployment ? (
                        <LoadingSpinner size={38}/>
                    ) : (
                        <>
                            <div className="col-6 text-end">
                                <button type="button" className="btn bg-accent me-3 text-white"
                                        onClick={() => createDeployment()} disabled={!name || !confidenceLevel}>
                                    {t("save")}
                                </button>
                            </div>
                            <div className="col-6">
                                <button type="button" className="btn bg-med ms-3 text-white" onClick={() => {
                                    navigate(routes.deployments.path);
                                }}>
                                    {t("cancel")}
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export { NewDeployment };

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { routes } from "helpers/routes";
import "./App.css";
import { authenticate, redirectToLogin } from "services/auth";
import { FullPageLoading } from "components/utils/ui";
import { DefaultPortalTemplate } from "components/templates";
import { NotAuthorized } from "components/pages/";

const AuthenticatedRoute = ({pageName, authGroups, ...rest}) => {
    const [authInfo, setAuthInfo] = useState({
        authenticated: null,
        user: null,
        email: null,
        authorized: null
    });
    const [PageComponent, setPageComponent] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const doAuthentication = async() => {
            try {
                const [authenticated, user, email, authorized, userGroups] = await authenticate(authGroups);
                if (authenticated === false && !!authGroups) {
                    redirectToLogin();
                    return;
                }
                setAuthInfo({
                    authenticated,
                    user,
                    email,
                    authorized,
                    userGroups
                });
            } catch (error) {
                console.error(`Error during authentication: ${error}`);
                setAuthInfo({
                    authenticated: false,
                    authorized: false
                });
            }
        };

        const loadPageComponent = async () => {
            try {
                const module = await import(`./components/pages`);
                setPageComponent(() => module[pageName]);
            } catch (error) {
                console.error(`Error loading page component: ${error}`);
            }
        };

        const initialize = async () => {
            await doAuthentication();
            await loadPageComponent();
        };

        initialize();
    }, [location]);

    if (
        authInfo.authenticated === null || //loading auth status
        PageComponent == null || //loading page
        (authInfo.authenticated === false && !!authGroups) //about to redirect
    ) {
        return (
            <DefaultPortalTemplate authInfo={authInfo}>
                <FullPageLoading/>
            </DefaultPortalTemplate>
        );
    }

    return (
        <DefaultPortalTemplate authInfo={authInfo}>
            {authGroups ? (
                authInfo.authorized ? (
                    <PageComponent {...rest} authInfo={authInfo}/>
                ) : (
                    <NotAuthorized/>
                )
            ) : (
                <PageComponent {...rest} authInfo={authInfo}/>
            )}
        </DefaultPortalTemplate>
    );
};

const App = () => {
    return (
        <Router>
            <Routes>
                {Object.values(routes).map((route) => {
                    return (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={
                                <AuthenticatedRoute
                                    pageName={route.component}
                                    authGroups={route.authGroups}
                                />
                            }
                        />
                    );
                })}
            </Routes>
        </Router>
    );
};

export default App;

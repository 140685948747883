import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Card,
    LoadingSpinner,
    PageBackButton,
    PageTitle,
    StaffSelector,
    TitleValueInput,
    TitleValueLine,
    tooltipStyles
} from "components/utils/ui";
import { dateObjectToDateString } from "helpers/date";
import { apiEndpoints, apiRequest } from "services/api";
import { routes, routeWithParams } from "helpers/routes";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";

const NewRobotReservation = () => {
    const {t} = useTranslation();
    const {robotId} = useParams();
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [notes, setNotes] = useState("");
    const [pointOfContact, setPointOfContact] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [isCreatingRobotReservation, setIsCreatingRobotReservation] = useState(false);
    const [data, setData] = useState(undefined);
    const [error, setError] = useState(undefined);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    useEffect(() => {
        if (startDate > endDate) setEndDate(startDate);
    }, [startDate]);
    useEffect(() => {
        if (endDate < startDate) setStartDate(endDate)
    }, [endDate]);

    const handleSelect = (ranges) => {
        if (ranges.dates) {
            const newStartDate = ranges.dates.startDate;
            const newEndDate = ranges.dates.endDate;
            setStartDate(newStartDate);
            setEndDate(newEndDate);
        }
    };

    const ranges = [
        {
            startDate: startDate,
            endDate: endDate,
            key: "dates",
            color: "#f25c07",
            autoFocus: true
        },
    ];

    const createRobotReservation = () => {
        if (!name) {
            window.alert(t("robotReservationNameRequired"));
            return;
        }
        setIsCreatingRobotReservation(true);
        apiRequest(
            setData,
            setError,
            "post",
            apiEndpoints.newRobotReservation,
            "",
            {
                name: name,
                startDate: dateObjectToDateString(startDate),
                endDate: dateObjectToDateString(endDate),
                robotId: robotId,
                pointOfContact: pointOfContact,
                notes: notes
            }
        ).then(apiRequestSuccess => {
            if (!apiRequestSuccess) alert(t("errorCreatingRobotReservation"));
            setIsCreatingRobotReservation(false);
        });
    }

    useEffect(() => {
        if (data === undefined || !data.robotReservationId) return;
        navigate(routeWithParams(routes.robotReservation.path, {id: data.robotReservationId}));
    }, [data]);

    return (
        <>
            <PageTitle className="align-items-center d-flex">
                <div>
                    <PageBackButton className="mb-1"
                                    baseUrl={routes.robot.path} params={{id: robotId}}>{robotId}</PageBackButton>
                    {t("newRobotReservation")}
                </div>
                <div className="flex-grow-1"></div>
            </PageTitle>
            <div className="d-flex flex-wrap">
                <div className="col-12 col-lg-6">
                    <Card>
                        <div className="fw-bold text-size-lg">{t("summary")}</div>
                        <div className="d-flex mt-2">
                            <TitleValueInput
                                title={`${t("name")}:`}
                                className="flex-grow-1"
                                value={name}
                                setValue={setName}
                            />
                            {!name && (
                                <>
                                    <FontAwesomeIcon icon={faExclamationCircle} className="text-color-accent"
                                                     data-tooltip-id="name-required-tooltip"/>
                                    <Tooltip
                                        id={`name-required-tooltip`}
                                        place="left"
                                        content={t("deploymentNameRequired")}
                                        style={tooltipStyles}
                                    />
                                </>
                            )}
                        </div>
                        <TitleValueInput
                            title={`${t("notes")}:`}
                            className="flex-grow-1"
                            value={notes}
                            setValue={setNotes}
                        />
                        <div className="d-flex">
                            <div className="fw-bold me-1">{`${t("pointOfContact")}:`}</div>
                            <div className="flex-grow-1 text-color-med">
                                <StaffSelector loaded={true}
                                               selectedStaff={pointOfContact}
                                               setSelectedStaff={setPointOfContact}
                                               allowNone={true}
                                />
                            </div>
                        </div>
                        <TitleValueLine
                            loaded={true}
                            title={`${t("robot")}:`}
                            value={robotId}
                        />
                    </Card>
                </div>
                <div className="d-flex flex-column col-12 col-lg-6">
                    <Card className="flex-grow-1">
                        <div className="text-center">
                            <div className="fw-bold text-size-lg my-2">{t("dates")}</div>
                            <DateRangePicker
                                onChange={handleSelect}
                                ranges={ranges}
                                shownDate={new Date()}
                                staticRanges={[]}
                                inputRanges={[]}
                                retainEndDateOnFirstSelection={true}
                            />
                            <div className="align-items-center d-flex justify-content-center mb-2 w-100">
                                <FontAwesomeIcon icon={faCircle}
                                                 className="text-color-accent ps-4"/>&nbsp;{t("reserved")}
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="col-12 my-2 my-lg-3 d-flex justify-content-center">
                    {isCreatingRobotReservation ? (
                        <LoadingSpinner size={38}/>
                    ) : (
                        <>
                            <div className="col-6 text-end">
                                <button type="button" className="btn bg-accent me-3 text-white"
                                        onClick={() => createRobotReservation()} disabled={!name}>
                                    {t("save")}
                                </button>
                            </div>
                            <div className="col-6">
                                <button type="button" className="btn bg-med ms-3 text-white" onClick={() => {
                                    navigate(routes.deployments.path);
                                }}>
                                    {t("cancel")}
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export { NewRobotReservation };

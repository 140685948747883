import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiEndpoints, apiRequest, createEmptyApiResponse } from "services/api";
import { Card, FullPageLoading, LinkWithParams, PageTitle, SearchableList, tooltipStyles } from "components/utils/ui";
import { routes } from "helpers/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";

const Sites = () => {
    const {t} = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);

    useEffect(() => {
        const getDataApiEndpoint = apiEndpoints.sites;
        createEmptyApiResponse(`get`, getDataApiEndpoint).then(emptyApiResponse => {
            if (!data && emptyApiResponse) setData(emptyApiResponse);
        });
        apiRequest(setData, setError, `get`, getDataApiEndpoint).then(apiRequestSuccess => {
            if (apiRequestSuccess) setLoaded(true);
        })
    }, []);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const sitesTableHeaders = {
        name: t("name"),
        location: t("location")
    }

    const processSites = (sites) => {
        return sites.map(site => {
            return {
                name: (<LinkWithParams baseUrl={routes.site.path} params={{id: site.id}} text={site.name}/>),
                location: site.location,
            };
        });
    };

    if (data === undefined) {
        return <FullPageLoading/>;
    }

    return (
        <>
            <PageTitle className="align-items-center d-flex">
                <div>
                    {t("sites")}
                </div>
                <div className="flex-grow-1"></div>
                <div className="clickable text-end text-decoration-none me-4">
                    <LinkWithParams data-tooltip-id="new-site-tooltip" baseUrl={routes.newSite.path}>
                        <FontAwesomeIcon icon={faPlus} className="text-color-accent"/>
                    </LinkWithParams>
                </div>
                <Tooltip
                    id={`new-site-tooltip`}
                    place="left"
                    content={t("newSite")}
                    style={tooltipStyles}
                />
            </PageTitle>
            <Card>
                <SearchableList
                    loaded={loaded}
                    title={t("allSites")}
                    columnNames={sitesTableHeaders}
                    data={processSites(data.sites)}
                />
            </Card>
        </>
    )
}

export { Sites };
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Card,
    FullPageLoading,
    getIconForFileExtension,
    LinkWithParams,
    LoadingSpinner,
    PageBackButton,
    PageTitle,
    SearchableList,
    TitleValueLine
} from "components/utils/ui";
import { apiEndpoints, apiRequest, createEmptyApiResponse } from "services/api";
import { useNavigate, useParams } from "react-router-dom";
import { routes, routeWithParams } from "helpers/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faHistory, faLock } from "@fortawesome/free-solid-svg-icons";
import { uniqueId } from "helpers/random";
import { dateObjectToPrettyDateWithYearAndTime } from "helpers/date";

const Filestore = () => {
    const {t} = useTranslation();
    const {id, path} = useParams();
    const navigate = useNavigate();

    const [loaded, setLoaded] = useState(false);
    const [loadedContents, setLoadedContents] = useState(false);
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);
    const [downloadUrl, setDownloadUrl] = useState();

    useEffect(() => {
        setLoadedContents(false);
        const getDataApiEndpoint = apiEndpoints.filestoreStorage;
        createEmptyApiResponse(`get`, getDataApiEndpoint.schemaPath).then(emptyApiResponse => {
            if (!data && emptyApiResponse) setData(emptyApiResponse);
        });
        apiRequest(setData, setError, `get`, getDataApiEndpoint.constructUrl(id, path ? decodeURIComponent(path) : "")).then(apiRequestSuccess => {
            if (apiRequestSuccess) {
                setLoaded(true);
                setLoadedContents(true);
            }
        })
    }, [path]);

    useEffect(() => {
        if (downloadUrl && downloadUrl.url) {
            window.open(downloadUrl.url)
            setDownloadUrl(null)
        }
    }, [downloadUrl])

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const downloadFile = (filePath, versionId = null) => {
        apiRequest(
            setDownloadUrl,
            setError,
            "get",
            apiEndpoints.filestoreDownload.constructUrl(id, `${decodeURIComponent(filePath)}${versionId ? `?version=${versionId}` : ``}`)
        );
    }

    const filestoreExplorerTableHeaders = {
        name: t("name"),
        size: t("fileSize"),
        lastModified: t("lastModified"),
        type: t("type"),
        action: null
    }

    const processContents = (contents) => {
        let formattedContents = [];
        contents.directories.forEach((directory) => {
            formattedContents.push({
                name: (
                    <>
                        <FontAwesomeIcon icon={faFolder} className="text-warning pe-2" fixedWidth={true}/>
                        <LinkWithParams
                            baseUrl={routes.filestore.path}
                            params={{
                                id: data ? data.summary.filestoreId : null,
                                path: encodeURIComponent(directory.path)
                            }}
                            text={directory.path.substring(directory.path.lastIndexOf(`/`) + 1)} key={uniqueId()}/>
                    </>
                ),
                size: null,
                lastModified: null
            });
        })
        contents.files.forEach((file) => {
            const {icon, color} = getIconForFileExtension(file.path);
            formattedContents.push({
                name: (
                    <div className="d-flex">
                        <FontAwesomeIcon
                            icon={icon}
                            style={{color: color}}
                            className="pe-2"
                            fixedWidth={true}
                        />
                        <div
                            className="clickable text-color-accent text-decoration-underline"
                            onClick={() => downloadFile(file.path)}
                        >
                            {file.path.substring(file.path.lastIndexOf(`/`) + 1)}
                        </div>
                    </div>
                ),
                size: file.size,
                lastModified: dateObjectToPrettyDateWithYearAndTime(new Date(file.lastModified)),
                type: file.mimeType,
                action: (
                    <LinkWithParams
                        baseUrl={routes.filestore.path}
                        params={{
                            id: data ? data.summary.filestoreId : null,
                            path: encodeURIComponent(file.path)
                        }}
                        key={uniqueId()}
                    >
                        <FontAwesomeIcon icon={faHistory} className="clickable text-color-accent pe-2"
                                         fixedWidth={true}/>
                    </LinkWithParams>

                )
            });
        });
        contents.versions.forEach((version, index) => {
            const {icon, color} = getIconForFileExtension(version.path);
            const reverseIndex = contents.versions.length - index;

            formattedContents.push({
                name: (
                    <div className="d-flex">
                        <FontAwesomeIcon
                            icon={icon}
                            style={{color: color}}
                            className="pe-2"
                            fixedWidth={true}
                        />
                        <div
                            className="clickable text-color-accent text-decoration-underline"
                            onClick={() => downloadFile(version.path, version.versionId)}
                        >
                            {`${version.path.substring(version.path.lastIndexOf(`/`) + 1)} (v${reverseIndex})`}
                        </div>
                    </div>
                ),
                size: version.size,
                lastModified: dateObjectToPrettyDateWithYearAndTime(new Date(version.lastModified)),
                type: version.mimeType
            });
        });
        return formattedContents;
    }

    if (data === undefined) {
        return <FullPageLoading/>;
    }

    return (
        <>
            <PageTitle className="align-items-center d-flex">
                <div>
                    {loaded ? (
                        <>
                            <PageBackButton
                                className="mb-1"
                                baseUrl={routeWithParams(routes.site.path, {id: data.summary.siteId})}
                            >
                                {data.summary.siteName}
                            </PageBackButton>
                            {data.summary.filestoreName}
                        </>
                    ) : (
                        <>
                            <PageBackButton className="mb-1" baseUrl={null}></PageBackButton>
                            <LoadingSpinner size={46}/>
                        </>
                    )}
                </div>
                <div className="flex-grow-1"></div>
            </PageTitle>
            {data.permission ? (
                <div className="d-flex flex-wrap">
                    <div className="col-12">
                        <Card>
                            <div className="fw-bold text-size-lg">{t("summary")}</div>
                            <TitleValueLine
                                loaded={loaded}
                                title={`${t("name")}:`}
                                value={data.summary.filestoreName}
                                className="mt-2"
                            />
                            <>
                                <TitleValueLine
                                    loaded={loaded}
                                    title={`${t("storageLocation")}:`}
                                    value={data.summary.storageLocation}
                                />
                                <TitleValueLine
                                    loaded={loaded}
                                    title={`${t("createdBy")}:`}
                                    value={
                                        <LinkWithParams baseUrl={routes.operator.path}
                                                        params={{id: data.summary.createdBy.id}}
                                                        text={data.summary.createdBy.name}/>
                                    }
                                />
                            </>
                        </Card>
                        <Card>
                            <SearchableList
                                loaded={loadedContents}
                                title={
                                    <>
                                        <div>{t("filestoreExplorer")}</div>
                                        <div className="d-flex flex-wrap fw-normal pb-2 text-size-md">
                                            <LinkWithParams className="text-color-accent text-decoration-underline pe-1"
                                                            baseUrl={routeWithParams(routes.filestore.path, {id: id})}>{data.summary.filestoreName}</LinkWithParams>

                                            {path && decodeURIComponent(path)
                                                .split(`/`)
                                                .map((item, index, arr) => {
                                                    // Accumulate the path up to the current item
                                                    const currentPath = encodeURIComponent(arr.slice(0, index + 1).join(`/`));

                                                    return (
                                                        <React.Fragment key={index}>
                                                            <div className="text-color-med pe-1">/</div>
                                                            <LinkWithParams
                                                                className="text-color-accent text-decoration-underline pe-1"
                                                                baseUrl={routeWithParams(routes.filestore.path, {
                                                                    id: id,
                                                                    path: currentPath
                                                                })}>
                                                                {item}
                                                            </LinkWithParams>
                                                        </React.Fragment>
                                                    );
                                                })}
                                        </div>
                                    </>

                                }
                                columnNames={filestoreExplorerTableHeaders}
                                data={processContents(data.contents)}
                            />
                        </Card>
                    </div>
                </div>
            ) : (
                <div className="d-flex justify-content-center align-items-center flex-grow-1">
                    <div className="text-center">
                        <FontAwesomeIcon icon={faLock} size="3x" style={{marginBottom: "8px"}}/>
                        <div className="fw-bold text-size-xl">{t("oops")}</div>
                        <div className="text-color-med">{t("noAccessToFilestore")}</div>
                    </div>
                </div>
            )}
        </>
    )
}

export { Filestore };
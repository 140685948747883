import { useEffect, useState } from "react";
import { apiEndpoints, apiRequest } from "services/api";
import { LoadingSpinner, SearchableSelect } from "components/utils/ui";
import { useTranslation } from "react-i18next";

const StaffSelector = ({loaded, selectedStaff, setSelectedStaff, allowNone = false}) => {
    const {t} = useTranslation();

    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);

    useEffect(() => {
        const getDataApiEndpoint = apiEndpoints.staffList;
        apiRequest(setData, setError, `get`, getDataApiEndpoint);
    }, []);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const processOptions = (data) => {
        if (allowNone) data[""] = t("parenthesisNone");
        return data;
    }

    if (data === undefined || !loaded) {
        return <LoadingSpinner/>;
    }

    return (
        <SearchableSelect loaded={true} options={processOptions(data)} setSelectedOption={setSelectedStaff}
                          selectedOption={selectedStaff}/>
    )

}

export { StaffSelector };
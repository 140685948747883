import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiEndpoints, apiRequest, createEmptyApiResponse } from "services/api";
import {
    Card,
    FullPageLoading,
    LinkWithParams,
    PageTitle,
    SearchableList,
    SegmentedControl
} from "components/utils/ui";
import { routes } from "helpers/routes";
import { uniqueId } from "helpers/random";

const Operators = () => {
    const {t} = useTranslation();
    const [error, setError] = useState(undefined);
    const [viewMode, setViewMode] = useState(Object.values(viewModes)[0]);
    const [operatorsData, setOperatorsData] = useState(undefined);
    const [operatorsLoaded, setOperatorsLoaded] = useState(false);

    const [allStaffData, setAllStaffData] = useState(undefined);
    const [allStaffLoaded, setAllStaffLoaded] = useState(false);

    useEffect(() => {
        const getOperatorsApiEndpoint = apiEndpoints.operators;
        const getAllStaffApiEndpoint = apiEndpoints.allStaff;
        createEmptyApiResponse(`get`, getOperatorsApiEndpoint).then(emptyApiResponse => {
            if (!operatorsData && emptyApiResponse) setOperatorsData(emptyApiResponse);
        });
        createEmptyApiResponse(`get`, getAllStaffApiEndpoint).then(emptyApiResponse => {
            if (!allStaffData && emptyApiResponse) setAllStaffData(emptyApiResponse);
        });
        apiRequest(setOperatorsData, setError, `get`, getOperatorsApiEndpoint).then(apiRequestSuccess => {
            if (apiRequestSuccess) setOperatorsLoaded(true);
        })
    }, []);

    useEffect(() => {
        if (viewMode === viewModes.allStaff) {
            const getAllStaffApiEndpoint = apiEndpoints.allStaff;
            apiRequest(setAllStaffData, setError, `get`, getAllStaffApiEndpoint).then(apiRequestSuccess => {
                if (apiRequestSuccess) setAllStaffLoaded(true);
            })
        }
    }, [viewMode]);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const segmentedControlOptions = {
        [viewModes.operators]: t("operators"),
        [viewModes.allStaff]: t("allStaff"),
    }

    const operatorsTableHeaders = {
        name: t("name"),
        status: t("status"),
        thisWeek: t("thisWeek"),
        nextWeek: t("nextWeek"),
        foreman: t("foreman"),
    }

    const allStaffTableHeaders = {
        name: t("name"),
        status: t("status"),
        thisWeek: t("thisWeek"),
        nextWeek: t("nextWeek"),
        operator: t("operator"),
        foreman: t("foreman"),
    }

    const processOperators = (operators) => {
        return operators.map(operator => {
            return {
                name: (
                    <LinkWithParams baseUrl={routes.operator.path} params={{id: operator.id}} text={operator.name}/>),
                status: (() => {
                    switch (operator.status) {
                        case "pto":
                            return t("pto");
                        case "uft":
                            return t("uft");
                        case "inOffice":
                            return t("inOffice");
                        case "deployed":
                            return t("deployed");
                        default:
                            return "Unknown status";
                    }
                })(),
                thisWeek: (() => {
                    const deployments = operator.thisWeek.reduce((acc, e, index) => {
                        if (index > 0) {
                            acc.push(", ");
                        }
                        acc.push(<LinkWithParams baseUrl={routes.deployment.path} params={{id: e.id}} text={e.name}
                                                 key={uniqueId()}/>);
                        return acc;
                    }, []);
                    return <>{deployments}</>;
                })(),
                nextWeek: (() => {
                    const deployments = operator.nextWeek.reduce((acc, e, index) => {
                        if (index > 0) {
                            acc.push(", ");
                        }
                        acc.push(<LinkWithParams baseUrl={routes.deployment.path} params={{id: e.id}} text={e.name}
                                                 key={uniqueId()}/>);
                        return acc;
                    }, []);
                    return <>{deployments}</>;
                })(),
                foreman: operator.foreman ? t("yes") : t("no"),
            };
        });
    };

    const processAllStaff = (staff) => {
        return staff.map(staffMember => {
            return {
                name: (
                    <LinkWithParams baseUrl={routes.operator.path} params={{id: staffMember.id}}
                                    text={staffMember.name}/>),
                status: (() => {
                    switch (staffMember.status) {
                        case "pto":
                            return t("pto");
                        case "uft":
                            return t("uft");
                        case "inOffice":
                            return t("inOffice");
                        case "deployed":
                            return t("deployed");
                        default:
                            return "Unknown status";
                    }
                })(),
                thisWeek: (() => {
                    const deployments = staffMember.thisWeek.reduce((acc, e, index) => {
                        if (index > 0) {
                            acc.push(", ");
                        }
                        acc.push(<LinkWithParams baseUrl={routes.deployment.path} params={{id: e.id}} text={e.name}
                                                 key={uniqueId()}/>);
                        return acc;
                    }, []);
                    return <>{deployments}</>;
                })(),
                nextWeek: (() => {
                    const deployments = staffMember.nextWeek.reduce((acc, e, index) => {
                        if (index > 0) {
                            acc.push(", ");
                        }
                        acc.push(<LinkWithParams baseUrl={routes.deployment.path} params={{id: e.id}} text={e.name}
                                                 key={uniqueId()}/>);
                        return acc;
                    }, []);
                    return <>{deployments}</>;
                })(),
                operator: staffMember.operator ? t("yes") : t("no"),
                foreman: staffMember.foreman ? t("yes") : t("no"),
            };
        });
    };

    if (operatorsData === undefined || allStaffData === undefined) {
        return <FullPageLoading/>;
    }

    return (
        <>
            <PageTitle>{t("operators")}</PageTitle>
            <Card>
                <div className="d-flex justify-content-center align-items-center">
                    <SegmentedControl options={segmentedControlOptions} setSelectedOption={setViewMode}
                                      selectedOption={viewMode} className="mb-4"/>
                </div>
                {viewMode === viewModes.operators && (
                    <SearchableList
                        loaded={operatorsLoaded}
                        columnNames={operatorsTableHeaders}
                        data={processOperators(operatorsData.operators)}
                    />
                )}
                {viewMode === viewModes.allStaff && (
                    <SearchableList
                        loaded={allStaffLoaded}
                        columnNames={allStaffTableHeaders}
                        data={processAllStaff(allStaffData.staff)}
                    />
                )}
            </Card>
        </>
    )
}

const viewModes = {
    operators: "operators",
    allStaff: "allStaff"
}

export { Operators };
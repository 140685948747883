export const generateEvenlySpacedColors = (numColors) => {
    const hueIncrement = 360 / numColors;
    let currentHue = 0;
    const colors = [];
    for (let i = 0; i < numColors; i++) {
        const color = `hsl(${currentHue}, 80%, 60%)`;
        colors.push(color);
        currentHue += hueIncrement;
    }
    return colors;
}
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, FullPageLoading, LoadingSpinner, PageTitle, SearchableSelect } from "components/utils/ui";
import { apiEndpoints, apiRequest, createEmptyApiResponse } from "services/api";
import { useNavigate, useParams } from "react-router-dom";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { routes, routeWithParams } from "helpers/routes";
import { useHotkeys } from "react-hotkeys-hook";
import { dateObjectToDateString } from "../../../helpers/date";

const LinkDeploymentAndSite = () => {
    const {t} = useTranslation();
    const {deploymentId, siteId} = useParams();
    const navigate = useNavigate();

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);

    const [newDeploymentId, setNewDeploymentId] = useState(null);
    const [newSiteId, setNewSiteId] = useState(null);

    const [isLinking, setIsLinking] = useState(false);

    useHotkeys("esc", () => navigateBack());

    useEffect(() => {
        if (!!deploymentId) {
            const getDataApiEndpoint = apiEndpoints.deploymentSiteOptions;
            createEmptyApiResponse(`get`, getDataApiEndpoint.schemaPath).then(emptyApiResponse => {
                if (!data && emptyApiResponse) setData(emptyApiResponse);
            });
            apiRequest(setData, setError, `get`, getDataApiEndpoint.constructUrl(deploymentId)).then(apiRequestSuccess => {
                if (apiRequestSuccess) setLoaded(true);
            });
        }
        if (!!siteId) {
            const getDataApiEndpoint = apiEndpoints.siteDeploymentOptions;
            createEmptyApiResponse(`get`, getDataApiEndpoint.schemaPath).then(emptyApiResponse => {
                if (!data && emptyApiResponse) setData(emptyApiResponse);
            });
            apiRequest(setData, setError, `get`, getDataApiEndpoint.constructUrl(siteId)).then(apiRequestSuccess => {
                if (apiRequestSuccess) setLoaded(true);
            });
        }
    }, []);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const processDeploymentOrSiteOptions = (options) => {
        let processedOptions = {};
        options.forEach(e => {
            processedOptions[e.id] = e.name;
        })
        return processedOptions;
    }

    const link = () => {
        const deploymentIdParam = !!deploymentId ? deploymentId : newDeploymentId;
        const siteIdParam = !!siteId ? siteId : newSiteId;
        apiRequest(
            (data) => {
            },
            setError,
            "post",
            apiEndpoints.linkDeploymentAndSite.constructUrl(deploymentIdParam, siteIdParam)
        ).then(apiRequestSuccess => {
            if (apiRequestSuccess) navigateBack();
            else alert(t("errorLinkingSiteAndDeployment"));
        });
    }

    const navigateBack = () => {
        if (!!deploymentId) navigate(routeWithParams(routes.deployment.path, {id: deploymentId}));
        if (!!siteId) navigate(routeWithParams(routes.site.path, {id: siteId}));
    }

    if (data === undefined) {
        return <FullPageLoading/>;
    }

    return (
        <>
            <PageTitle>
                {loaded ? (
                    <>
                        {!!deploymentId && t(`linkSiteToSpecifiedDeployment`, {deployment: data.deployment.name})}
                        {!!siteId && t(`linkDeploymentToSpecifiedSite`, {site: data.site.name})}
                    </>
                ) : (
                    <LoadingSpinner size={46}/>
                )}
            </PageTitle>
            <div className="d-flex flex-wrap align-items-stretch">
                <div className="d-flex flex-column col-12 col-lg-6">
                    <Card className="flex-grow-1">
                        <div
                            className="align-items-center d-flex flex-column h-100 justify-content-center">
                            <div className="fw-bold text-size-xl my-3">{t("deployment")}</div>
                            {loaded ? (
                                <>
                                    {!!deploymentId && (
                                        <div className="mb-3 col-6">
                                            <SearchableSelect loaded={loaded}
                                                              options={{[deploymentId]: data.deployment.name}}
                                                              placeholder={t("selectDeployment")}
                                                              setSelectedOption={() => {
                                                              }}
                                                              selectedOption={deploymentId}/>
                                        </div>
                                    )}
                                    {!!siteId && (
                                        <div className="mb-3 col-6">
                                            <SearchableSelect loaded={loaded}
                                                              options={processDeploymentOrSiteOptions(data.deployments)}
                                                              placeholder={t("selectDeployment")}
                                                              setSelectedOption={deploymentId => setNewDeploymentId(deploymentId)}
                                                              selectedOption={deploymentId}/>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <LoadingSpinner size={24}/>
                            )}
                        </div>
                    </Card>
                </div>
                <div className="d-flex flex-column col-12 col-lg-6">
                    <Card className="flex-grow-1">
                        <div
                            className="align-items-center d-flex flex-column h-100 justify-content-center">
                            <div className="fw-bold text-size-xl my-3">{t("site")}</div>
                            {loaded ? (
                                <>
                                    {!!siteId && (
                                        <div className="mb-3 col-6">
                                            <SearchableSelect loaded={loaded}
                                                              options={{[siteId]: data.site.name}}
                                                              placeholder={t("selectSite")}
                                                              setSelectedOption={() => {
                                                              }}
                                                              selectedOption={siteId}/>
                                        </div>
                                    )}
                                    {!!deploymentId && (
                                        <div className="mb-3 col-6">
                                            <SearchableSelect loaded={loaded}
                                                              options={processDeploymentOrSiteOptions(data.sites)}
                                                              placeholder={t("selectSite")}
                                                              setSelectedOption={siteId => setNewSiteId(siteId)}
                                                              selectedOption={siteId}/>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <LoadingSpinner size={24}/>
                            )}
                        </div>
                    </Card>
                </div>
                <div className="col-12 my-2 my-lg-3 d-flex justify-content-center">
                    {isLinking ? (
                        <LoadingSpinner size={38}/>
                    ) : (
                        <>
                            <div className="col-6 text-end">
                                <button type="button" className="btn bg-accent me-2 me-lg-3 text-white"
                                        onClick={() => link()} disabled={(!!deploymentId && !newSiteId) || (!!siteId && !newDeploymentId)}>
                                    {t("link")}
                                </button>
                            </div>
                            <div className="col-6">
                                <button type="button" className="btn bg-med ms-2 ms-lg-3 text-white"
                                        onClick={() => navigateBack()}>
                                    {t("cancel")}
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export { LinkDeploymentAndSite };

import { useEffect, useState } from "react";
import { apiEndpoints, apiRequest } from "services/api";
import { LoadingSpinner, SearchableSelect } from "components/utils/ui";
import { useTranslation } from "react-i18next";

const RobotMaintenanceStatusSelector = ({loaded, robotMaintenanceStatus, setRobotMaintenanceStatus}) => {
    const {t} = useTranslation();
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);

    useEffect(() => {
        const getDataApiEndpoint = apiEndpoints.robotMaintenanceStatusList;
        apiRequest(setData, setError, `get`, getDataApiEndpoint);
    }, []);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const processOptions = (data) => {
        return data.reduce((obj, value) => {
            obj[value] = t(`api:robot_maintenance_status_list.${value}`);
            return obj;
        }, {});
    }

    if (data === undefined || !loaded) {
        return <LoadingSpinner/>;
    }

    return (
        <SearchableSelect loaded={true} options={processOptions(data)} setSelectedOption={setRobotMaintenanceStatus}
                          selectedOption={robotMaintenanceStatus}/>
    )

}

export { RobotMaintenanceStatusSelector };
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, LoadingSpinner, PageTitle, SearchableSelect, TitleValueInput } from "components/utils/ui";
import { apiEndpoints, apiFileRequest } from "services/api";
import { dateObjectToDateString } from "helpers/date";

const Reports = () => {
    const {t} = useTranslation();

    const [type, setType] = useState(null);
    const [startDate, setStartDate] = useState(dateObjectToDateString(new Date()));
    const [endDate, setEndDate] = useState(dateObjectToDateString(new Date()));
    const [includeTravelDays, setIncludeTravelDays] = useState(false);

    const [isGeneratingReport, setIsGeneratingReport] = useState(false);
    const [error, setError] = useState(undefined);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const handleIncludeTravelDaysChange = (event) => {
        setIncludeTravelDays(event.target.checked);
    };

    const generateReport = () => {
        setIsGeneratingReport(true);
        apiFileRequest(
            setError,
            "get",
            apiEndpoints.generateUtilizationReport,
            "",
            {
                type: type,
                startDate: startDate,
                endDate: endDate,
                includeTravelDays: includeTravelDays,
            }
        ).then(e => {
            setIsGeneratingReport(false);
        });
    }

    return (
        <>
            <PageTitle>{t("reports")}</PageTitle>
            <Card>
                <div className="fw-bold text-size-lg">{t("generateReport")}</div>
                <div className="d-flex mt-2">
                    <div className="fw-bold me-1">{`${t("reportType")}:`}</div>
                    <div className="text-color-med flex-grow-1">
                        <SearchableSelect
                            loaded={true}
                            options={{
                                robot: t("robots"),
                                staff: t("staff")
                            }}
                            placeholder={t("reportType")}
                            selectedOption={type}
                            setSelectedOption={setType}
                        />
                    </div>
                </div>
                <TitleValueInput
                    title={`${t("startDate")}:`}
                    value={startDate}
                    setValue={setStartDate}
                    type="date"
                />
                <TitleValueInput
                    title={`${t("endDate")}:`}
                    value={endDate}
                    setValue={setEndDate}
                    type="date"
                />
                <div className="d-flex">
                    <div className="fw-bold me-1">{`${t("includeTravelDays")}:`}</div>
                    <div className="form-switch">
                        <input className="form-check-input" type="checkbox" checked={includeTravelDays}
                               onChange={handleIncludeTravelDaysChange}/>
                    </div>
                </div>
            </Card>
            <div className="col-12 pt-3 d-flex justify-content-center">
                {isGeneratingReport ? (
                    <LoadingSpinner size={38}/>
                ) : (
                    <>
                        <button type="button" className="btn bg-accent me-3 text-white"
                                onClick={() => generateReport()} disabled={!type || !startDate || !endDate}>
                            {t("generate")}
                        </button>
                    </>
                )}
            </div>
        </>
    )
}

export { Reports };
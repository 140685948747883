import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiEndpoints, apiRequest, createEmptyApiResponse } from "services/api";
import {
    Card,
    FullPageLoading,
    LinkWithParams,
    PageTitle,
    SearchableList,
    SegmentedControl,
    tooltipStyles
} from "components/utils/ui";
import { routes } from "helpers/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { convertToPrettyDate, convertToPrettyDateWithYear } from "helpers/date";
import { Tooltip } from "react-tooltip";
import { uniqueId } from "helpers/random";

const Deployments = () => {
    const {t} = useTranslation();
    const [error, setError] = useState(undefined);
    const [viewMode, setViewMode] = useState(Object.values(viewModes)[0]);
    const [deploymentsLoaded, setDeploymentsLoaded] = useState(false);
    const [deploymentsData, setDeploymentsData] = useState(undefined);
    const [allDeploymentsLoaded, setAllDeploymentsLoaded] = useState(false);
    const [allDeploymentsData, setAllDeploymentsData] = useState(undefined);

    useEffect(() => {
        const getDeploymentsApiEndpoint = apiEndpoints.deployments;
        const getAllDeploymentsApiEndpoint = apiEndpoints.allDeployments;
        createEmptyApiResponse(`get`, getDeploymentsApiEndpoint).then(emptyApiResponse => {
            if (!deploymentsData && emptyApiResponse) setDeploymentsData(emptyApiResponse);
        });
        createEmptyApiResponse(`get`, getAllDeploymentsApiEndpoint).then(emptyApiResponse => {
            if (!deploymentsData && emptyApiResponse) setAllDeploymentsData(emptyApiResponse);
        });
        apiRequest(setDeploymentsData, setError, `get`, getDeploymentsApiEndpoint).then(apiRequestSuccess => {
            if (apiRequestSuccess) setDeploymentsLoaded(true);
        })
    }, []);

    useEffect(() => {
        if (viewMode === viewModes.all) {
            const getAllDeploymentsApiEndpoint = apiEndpoints.allDeployments;
            apiRequest(setAllDeploymentsData, setError, `get`, getAllDeploymentsApiEndpoint).then(apiRequestSuccess => {
                if (apiRequestSuccess) setAllDeploymentsLoaded(true);
            })
        }
    }, [viewMode]);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const segmentedControlOptions = {
        [viewModes.upcoming]: t("upcoming"),
        [viewModes.all]: t("all"),
    }

    const deploymentsTableHeaders = {
        name: t("name"),
        travelDates: t("travelDates"),
        location: t("location"),
        robots: t("robots"),
        staff: t("staff"),
        foremanAssigned: t("foremanAssigned"),
        confidenceLevel: t("confidence")
    }

    const allDeploymentsTableHeaders = {
        name: t("name"),
        travelDates: t("travelDates"),
        location: t("location"),
        robots: t("robots"),
        staff: t("staff"),
        confidenceLevel: t("confidence")
    }

    const processDeployments = (deployments) => {
        return deployments.map(deployment => {
            return {
                name: (
                    <LinkWithParams baseUrl={routes.deployment.path} params={{id: deployment.id}}
                                    text={deployment.name} key={uniqueId()}/>
                ),
                travelDates: `${convertToPrettyDate(deployment.travelStartDate)}-${convertToPrettyDate(deployment.travelEndDate)}`,
                location: deployment.location,
                robots: (() => {
                    const robots = deployment.robots.reduce((acc, e, index) => {
                        if (index > 0) {
                            acc.push(", ");
                        }
                        acc.push(<LinkWithParams baseUrl={routes.robot.path} params={{id: e.id}} text={e.name}
                                                 key={uniqueId()}/>);
                        return acc;
                    }, []);
                    return (
                        <div>
                            {deployment.robotsAssigned}/{deployment.robotsRequired}{!!deployment.robots.length && (<>&nbsp;({robots})</>)}
                        </div>
                    );
                })(),
                staff: (() => {
                    const staff = deployment.staff.reduce((acc, e, index) => {
                        if (index > 0) {
                            acc.push(", ");
                        }
                        acc.push(<LinkWithParams baseUrl={routes.operator.path} params={{id: e.id}} text={e.name}
                                                 key={uniqueId()}/>);
                        return acc;
                    }, []);
                    return (
                        <div>
                            {deployment.staffAssigned}/{deployment.staffRequired}{!!deployment.staff.length && (<>&nbsp;({staff})</>)}
                        </div>
                    );
                })(),
                foremanAssigned: deployment.staffRequired === 0 ? t("na") : (deployment.foremanAssigned ? t("yes") : t("no")),
                confidenceLevel: t(`api:deployment_confidence_level_list.${deployment.confidenceLevel}`)
            };
        });
    };

    const processAllDeployments = (deployments) => {
        return deployments.map(deployment => {
            return {
                name: (
                    <LinkWithParams baseUrl={routes.deployment.path} params={{id: deployment.id}}
                                    text={deployment.name}/>
                ),
                travelDates: `${convertToPrettyDateWithYear(deployment.travelStartDate)}-${convertToPrettyDateWithYear(deployment.travelEndDate)}`,
                location: deployment.location,
                robots: deployment.robotsRequired,
                staff: deployment.staffRequired,
                confidenceLevel: t(`api:deployment_confidence_level_list.${deployment.confidenceLevel}`)
            };
        });
    };

    if (deploymentsData === undefined || allDeploymentsData === undefined) {
        return <FullPageLoading/>;
    }

    return (
        <>
            <PageTitle className="align-items-center d-flex">
                <div>
                    {t("deployments")}
                </div>
                <div className="flex-grow-1"></div>
                <div className="clickable text-end text-decoration-none">
                    <LinkWithParams data-tooltip-id="new-deployment-tooltip" baseUrl={routes.newDeployment.path}>
                        <FontAwesomeIcon icon={faPlus} className="text-color-accent"/>
                    </LinkWithParams>
                </div>
                <Tooltip
                    id={`new-deployment-tooltip`}
                    place="left"
                    content={t("newDeployment")}
                    style={tooltipStyles}
                />
            </PageTitle>
            <Card>
                <div className="d-flex justify-content-center">
                    <SegmentedControl options={segmentedControlOptions} setSelectedOption={setViewMode}
                                      selectedOption={viewMode} className="mb-4"/>
                </div>
                {viewMode === viewModes.upcoming && (
                    <SearchableList
                        loaded={deploymentsLoaded}
                        columnNames={deploymentsTableHeaders}
                        data={processDeployments(deploymentsData.deployments)}
                    />
                )}
                {viewMode === viewModes.all && (
                    <SearchableList
                        loaded={allDeploymentsLoaded}
                        columnNames={allDeploymentsTableHeaders}
                        data={processAllDeployments(allDeploymentsData.deployments)}
                    />
                )}
            </Card>
        </>
    )
}

const viewModes = {
    upcoming: "upcoming",
    all: "all"
}

export { Deployments };
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiEndpoints, apiRequest, createEmptyApiResponse } from "services/api";
import {
    Card,
    FullPageLoading,
    LinkWithParams,
    LoadingSpinner,
    PageBackButton,
    PageTitle,
    SearchableList,
    tooltipStyles
} from "components/utils/ui";
import { useNavigate, useParams } from "react-router-dom";
import { routes, routeWithParams } from "helpers/routes";
import { dateObjectToPrettyDateWithYearAndTime } from "helpers/date";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import { useHotkeys } from "react-hotkeys-hook";
import { robotNavigationMap } from "components/utils/navigation";

const RobotMaintenanceHistory = () => {
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);

    useHotkeys("esc", () => navigate(routeWithParams(routes.robot.path, {id: id})));
    useHotkeys("shift+right", () => navigate(routeWithParams(routes.robotMaintenanceHistory.path, {id: robotNavigationMap[id].next})));
    useHotkeys("shift+left", () => navigate(routeWithParams(routes.robotMaintenanceHistory.path, {id: robotNavigationMap[id].prev})));
    useHotkeys("u", () => navigate(routeWithParams(routes.updateRobotMaintenanceStatus.path, {id: id})));
    useHotkeys("c", () => navigate(routeWithParams(routes.robotCalibrationHistory.path, {id: id})));

    useEffect(() => {
        setLoaded(false);
        const getDataApiEndpoint = apiEndpoints.robotMaintenanceHistory;
        createEmptyApiResponse(`get`, getDataApiEndpoint).then(emptyApiResponse => {
            if (!data && emptyApiResponse) setData(emptyApiResponse);
        });
        apiRequest(setData, setError, `get`, getDataApiEndpoint, id).then(apiRequestSuccess => {
            if (apiRequestSuccess) setLoaded(true);
        })
    }, [id]);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const robotMaintenanceEventsTableHeaders = {
        timestamp: t("date"),
        status: t("status"),
        notes: t("notes"),
        updatedBy: t("updatedBy")
    }

    const processRobotMaintenanceEvents = (events) => {
        return events.map(event => {
            const date = new Date(event.createdAt);
            return {
                timestamp: dateObjectToPrettyDateWithYearAndTime(date),
                status: t(`api:robot_maintenance_status_list.${event.status}`),
                notes: event.notes,
                updatedBy: (<LinkWithParams baseUrl={routes.operator.path} params={{id: event.user.id}}
                                            text={event.user.name}/>),
            };
        });
    };

    if (data === undefined) {
        return <FullPageLoading/>;
    }

    return (
        <>
            <PageTitle className="align-items-center d-flex">
                <div>
                    <PageBackButton className="mb-1" baseUrl={routes.robot.path} params={{id: id}}>{id}</PageBackButton>
                    {loaded ? (
                        t("robotMaintenanceHistory", {robot: id})
                    ) : (
                        <LoadingSpinner size={46}/>
                    )}
                </div>
                <div className="flex-grow-1"></div>
                {loaded && (
                    <>
                        <div className="clickable text-end text-decoration-none">
                            <LinkWithParams data-tooltip-id="update-tooltip"
                                            baseUrl={routes.updateRobotMaintenanceStatus.path} params={{id: id}}>
                                <FontAwesomeIcon icon={faEdit} className="text-color-accent"/>
                            </LinkWithParams>
                        </div>
                        <Tooltip
                            id={`update-tooltip`}
                            place="left"
                            content={t("update")}
                            style={tooltipStyles}
                        />
                    </>
                )}
            </PageTitle>
            <Card>
                <SearchableList
                    loaded={loaded}
                    title={t("history")}
                    columnNames={robotMaintenanceEventsTableHeaders}
                    data={processRobotMaintenanceEvents(data.maintenanceHistory)}
                    sortable={false}
                />
            </Card>
        </>
    )
}

export { RobotMaintenanceHistory };
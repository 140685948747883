import { routes, routeWithParams } from "helpers/routes";
import { calendarPatterns } from "components/utils/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faDatabase, faFile, faFileCode, faFileCsv } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useTranslation } from "react-i18next";

export const invisibleInputStyles = {
    background: "transparent",
    border: "0",
    color: "inherit",
    font: "inherit",
    margin: 0,
    outline: "none",
    padding: 0,
    width: "100%",
}

export const textColorForBackground = (bgColor) => {
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
        "#000000" : "#ffffff";
}

export const halfColor = (hex) => {
    if (!hex.startsWith("#")) {
        hex = `#${hex}`;
    }

    const hexToRGBA = (hex) => {
        let r = 0, g = 0, b = 0;
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        } else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        }
        return {r, g, b, a: 0.5};
    };

    const blendWithWhite = (rgba) => {
        const blendChannel = (color, alpha) => {
            return Math.round((1 - alpha) * 255 + alpha * color);
        };
        const r = blendChannel(rgba.r, rgba.a);
        const g = blendChannel(rgba.g, rgba.a);
        const b = blendChannel(rgba.b, rgba.a);
        const componentToHex = (c) => {
            const hex = c.toString(16);
            return hex.length === 1 ? `0${hex}` : hex;
        };
        return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
    };
    const rgbaColor = hexToRGBA(hex);
    return blendWithWhite(rgbaColor);
};


export const colors = {
    // Primary Palette
    accent: "#f25c07",
    accentTrans: "#f8a089", // "#f25c0724", THIS COLOR IS FLATTENED/BLENDED WITH WHITE TO REMOVE ALPHA
    accentXtrans: "#fbf1eb", // "#f25c0708", THIS COLOR IS FLATTENED/BLENDED WITH WHITE TO REMOVE ALPHA
    black: "#000000",
    danger: "#dc3545",
    dangerSubtle: "#ea868f",
    dark: "#212b36",
    light: "#919eab",
    lightTrans: "#aab5bd", // "#919eab32", THIS COLOR IS FLATTENED/BLENDED WITH WHITE TO REMOVE ALPHA
    lightXtrans: "#d0d5da", // "#919eab16", THIS COLOR IS FLATTENED/BLENDED WITH WHITE TO REMOVE ALPHA
    lightXxtrans: "#e8ebed",
    med: "#637378",
    primary: "#0d6efd",
    primarySubtle: "#6ea8fe",
    success: "#198754",
    successSubtle: "#75b798",
    warning: "#ffc107",
    warningSubtle: "#ffda6a",
    white: "#ffffff",

    // Blue
    blue100: "#cfe2ff",
    blue200: "#9ec5fe",
    blue300: "#6ea8fe",
    blue400: "#3d8bfd",
    blue500: "#0d6efd",
    blue600: "#0a58ca",
    blue700: "#084298",
    blue800: "#052c65",
    blue900: "#031633",

    // Indigo
    indigo100: "#e0cffc",
    indigo200: "#c29ffa",
    indigo300: "#a370f7",
    indigo400: "#8440f5",
    indigo500: "#6610f2",
    indigo600: "#520dc2",
    indigo700: "#3d0a91",
    indigo800: "#290661",
    indigo900: "#140330",

    // Purple
    purple100: "#e9d7ff",
    purple200: "#d0b2ff",
    purple300: "#b088ff",
    purple400: "#944bff",
    purple500: "#6f42c1",
    purple600: "#5f3fa0",
    purple700: "#4c2e84",
    purple800: "#3c2167",
    purple900: "#2e194f",

    // Pink
    pink100: "#f8d7da",
    pink200: "#f4a8b4",
    pink300: "#f1758f",
    pink400: "#ed4370",
    pink500: "#d63384",
    pink600: "#be2672",
    pink700: "#a2205e",
    pink800: "#851a49",
    pink900: "#680f36",

    // Red
    red100: "#ffe3e3",
    red200: "#ffbdbd",
    red300: "#ff9b9b",
    red400: "#f86c6b",
    red500: "#dc3545",
    red600: "#bd2130",
    red700: "#9b1b29",
    red800: "#7a1822",
    red900: "#611420",

    // Orange
    orange100: "#ffe8cc",
    orange200: "#ffd5a3",
    orange300: "#ffb777",
    orange400: "#ff8e50",
    orange500: "#fd7e14",
    orange600: "#e26711",
    orange700: "#c0540e",
    orange800: "#9b410b",
    orange900: "#773209",

    // Yellow
    yellow100: "#fff3cd",
    yellow200: "#ffe69c",
    yellow300: "#ffdd75",
    yellow400: "#ffce3d",
    yellow500: "#ffc107",
    yellow600: "#e0a800",
    yellow700: "#cc9a00",
    yellow800: "#b68b00",
    yellow900: "#a07600",

    // Green
    green100: "#d1e7dd",
    green200: "#a3cfbb",
    green300: "#75b798",
    green400: "#479f76",
    green500: "#198754",
    green600: "#157347",
    green700: "#11603b",
    green800: "#0c4c2e",
    green900: "#08381e",

    // Teal
    teal100: "#d2f4ea",
    teal200: "#a4eadb",
    teal300: "#73dfc7",
    teal400: "#45d6b3",
    teal500: "#20c997",
    teal600: "#1aae83",
    teal700: "#188468",
    teal800: "#146a55",
    teal900: "#115244",

    // Cyan
    cyan100: "#cff4fc",
    cyan200: "#9eeaf9",
    cyan300: "#6edff6",
    cyan400: "#3dd5f3",
    cyan500: "#0dcaf0",
    cyan600: "#0bbcd6",
    cyan700: "#099aa4",
    cyan800: "#057780",
    cyan900: "#034f52",

    // Gray
    gray100: "#f8f9fa",
    gray200: "#e9ecef",
    gray300: "#dee2e6",
    gray400: "#ced4da",
    gray500: "#adb5bd",
    gray600: "#6c757d",
    gray700: "#495057",
    gray800: "#343a40",
    gray900: "#212529"
};


export const tooltipStyles = {
    backgroundColor: "var(--dark)",
    borderRadius: "12px",
    color: "var(--white)",
    fontSize: "14px",
    padding: "4px 8px",
}

export const stateColors = {
    inMaintenance: "#dc3545",
    uncalibrated: "#dc3545",
    needsBlockingMaintenance: "#dc3545",
    pto: "#dc3545",
    inMaintenanceCalendar: '#ea868f',
    ptoCalendar: "#dc3545",
    inMaintenanceChart: '#ea868f',
    developmentReady: "#ffc107",
    inCalibration: "#ffc107",
    inCalibrationCheckout: "#ffc107",
    needsNonBlockingMaintenance: "#ffc107",
    uft: "#ffc107",
    developmentReadyCalendar: "#ffda6a",
    uftCalendar: "#ffc107",
    developmentReadyChart: "#ffda6a",
    jobReady: "#0d6efd",
    checkInTesting: "#0d6efd",
    calibrated: "#0d6efd",
    maintenanceNotRequired: "#0d6efd",
    inOffice: "#0d6efd",
    jobReadyCalendar: "#6ea8fe",
    jobReadyChart: "#6ea8fe",
    deployed: "#198754",
    deployedCalendar: "#198754",
    deployedChart: "#75b798",
    reserved: "#6f42c1",
    reservedCalendar: "#6f42c1",
    reservedChart: "#b088ff",
}

export function styleCalendarData(e) {
    let calendarEvent = {
        name: e.name,
        url: null,
        travelStartDate: e.travelStartDate,
        startDate: e.startDate,
        endDate: e.endDate,
        travelEndDate: e.travelEndDate,
        row: e.belongsTo,
    };
    //deployment
    if (e.identifier === "scheduled") {
        calendarEvent.color = stateColors.deployedCalendar;
        calendarEvent.url = routeWithParams(routes.deployment.path, {id: e.link});
    }
    if (e.identifier === "tentative") {
        calendarEvent.color = stateColors.deployedCalendar;
        calendarEvent.outlineColor = stateColors.deployedCalendar;
        calendarEvent.textColor = colors.dark;
        calendarEvent.pattern = calendarPatterns.cross;
        calendarEvent.url = routeWithParams(routes.deployment.path, {id: e.link});
    }
    if (e.identifier === "placeholder") {
        calendarEvent.color = stateColors.deployedCalendar;
        calendarEvent.outlineColor = stateColors.deployedCalendar;
        calendarEvent.textColor = colors.dark;
        calendarEvent.pattern = calendarPatterns.blank;
        calendarEvent.url = routeWithParams(routes.deployment.path, {id: e.link});
    }
    //reservation
    if (e.identifier === "reserved") {
        calendarEvent.color = stateColors.reservedCalendar;
        calendarEvent.url = null;//routeWithParams(routes.robotReservation.path, {id: e.link});
    }
    //robot
    if (e.identifier === "inMaintenance") calendarEvent.color = stateColors.inMaintenanceCalendar;
    if (e.identifier === "developmentReady") calendarEvent.color = stateColors.developmentReadyCalendar;
    if (e.identifier === "jobReady") calendarEvent.color = stateColors.jobReadyCalendar;
    //staff
    if (e.identifier === "pto") calendarEvent.color = stateColors.ptoCalendar;
    if (e.identifier === "uft") calendarEvent.color = stateColors.uftCalendar;
    return calendarEvent;
}

export const DeploymentCalendarLegend = () => {
    const {t} = useTranslation();
    return (
        <div className="align-items-center d-flex mt-1">
            <FontAwesomeIcon icon={faCircle}
                             style={{color: stateColors.deployedCalendar}}/>&nbsp;{t("deployed")}
        </div>
    );
}

export const RobotCalendarLegend = () => {
    const {t} = useTranslation();
    return (
        <div className="align-items-center d-flex flex-wrap mt-1">
            <div className="align-items-center d-flex flex-nowrap">
                <FontAwesomeIcon icon={faCircle}
                                 style={{color: stateColors.reservedCalendar}}/>&nbsp;{t("reserved")}
                <div className="me-4"></div>
                <FontAwesomeIcon icon={faCircle}
                                 style={{color: stateColors.deployedCalendar}}/>&nbsp;{t("deployed")}
                <div className="me-4"></div>
            </div>
            <div className="align-items-center d-flex flex-nowrap">
                <FontAwesomeIcon icon={faCircle}
                                 style={{color: stateColors.jobReadyCalendar}}/>&nbsp;{t("jobReady")}
                <div className="me-4"></div>
                <FontAwesomeIcon icon={faCircle}
                                 style={{color: stateColors.developmentReadyCalendar}}/>&nbsp;{t("developmentReady")}
                <div className="me-4"></div>
            </div>
            <div className="align-items-center d-flex flex-nowrap">
                <FontAwesomeIcon icon={faCircle}
                                 style={{color: stateColors.inMaintenanceCalendar}}/>&nbsp;{t("inMaintenance")}
                <div className="me-4"></div>
            </div>
        </div>
    );
}

export const RobotPieLegend = () => {
    const {t} = useTranslation();
    return (
        <div className="align-items-center d-flex flex-wrap mt-3">
            <div className="align-items-center d-flex flex-nowrap">
                <FontAwesomeIcon icon={faCircle}
                                 style={{color: stateColors.reserved}}/>&nbsp;{t("reserved")}
                <div className="me-4"></div>
                <FontAwesomeIcon icon={faCircle}
                                 style={{color: stateColors.deployed}}/>&nbsp;{t("deployed")}
                <div className="me-4"></div>
            </div>
            <div className="align-items-center d-flex flex-nowrap">
                <FontAwesomeIcon icon={faCircle}
                                 style={{color: stateColors.jobReady}}/>&nbsp;{t("jobReady")}
                <div className="me-4"></div>
                <FontAwesomeIcon icon={faCircle}
                                 style={{color: stateColors.developmentReady}}/>&nbsp;{t("developmentReady")}
                <div className="me-4"></div>
            </div>
            <div className="align-items-center d-flex flex-nowrap">
                <FontAwesomeIcon icon={faCircle}
                                 style={{color: stateColors.inMaintenance}}/>&nbsp;{t("inMaintenance")}
                <div className="me-4"></div>
            </div>
        </div>
    );
}

export const StaffCalendarLegend = () => {
    const {t} = useTranslation();
    return (

        <div className="align-items-center d-flex mt-1">
            <FontAwesomeIcon icon={faCircle}
                             style={{color: stateColors.deployedCalendar}}/>&nbsp;{t("deployed")}
            <div className="me-4"></div>
            <FontAwesomeIcon icon={faCircle}
                             style={{color: stateColors.uftCalendar}}/>&nbsp;{t("uft")}
            <div className="me-4"></div>
            <FontAwesomeIcon icon={faCircle}
                             style={{color: stateColors.ptoCalendar}}/>&nbsp;{t("pto")}
            <div className="me-4"></div>
        </div>
    );
}

export const getIconForFileExtension = (fileName) => {
    const lowerCaseFileName = fileName.toLowerCase();

    switch (true) {
        case lowerCaseFileName.endsWith(".json"):
            return { icon: faFileCode, color: colors.blue500 };
        case lowerCaseFileName.endsWith(".yaml") || lowerCaseFileName.endsWith(".yml"):
            return { icon: faFileCode, color: colors.blue500 };
        case lowerCaseFileName.endsWith(".csv"):
            return { icon: faFileCsv, color: colors.green500 };
        case lowerCaseFileName.endsWith(".db"):
            return { icon: faDatabase, color: colors.purple500 };
        default:
            return { icon: faFile, color: colors.med };
    }
};

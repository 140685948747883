import { useEffect, useState } from "react";
import { apiEndpoints, apiRequest } from "services/api";
import { LoadingSpinner, SearchableSelect } from "components/utils/ui";

const DealSelector = ({loaded, selectedDeal, setSelectedDeal}) => {
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);

    useEffect(() => {
        const getDataApiEndpoint = apiEndpoints.deals;
        apiRequest(setData, setError, `get`, getDataApiEndpoint);
    }, []);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const processDeals = (deals) => {
        return deals.reduce((acc, curr) => {
            acc[curr.id] = curr.name;
            return acc;
        }, {});
    }

    if (data === undefined || !loaded) {
        return <LoadingSpinner/>;
    }

    return (
        <SearchableSelect loaded={true} options={processDeals(data.deals)} setSelectedOption={setSelectedDeal}
                          selectedOption={selectedDeal}/>
    )

}

export { DealSelector };
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Card,
    FullPageLoading,
    LoadingSpinner,
    PageBackButton,
    PageTitle,
    TitleValueInput,
    TitleValueLine,
    tooltipStyles
} from "components/utils/ui";
import { apiEndpoints, apiRequest, createEmptyApiResponse } from "services/api";
import { routes, routeWithParams } from "helpers/routes";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faLock } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import { useHotkeys } from "react-hotkeys-hook";

const NewFilestore = ({authInfo}) => {
    const {t} = useTranslation();
    const {siteId} = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [name, setName] = useState("");
    const [storageLocation, setStorageLocation] = useState("AWS");

    const [loaded, setLoaded] = useState(false);
    const [isCreatingFilestore, setIsCreatingFilestore] = useState(false);
    const [data, setData] = useState(undefined);
    const [createFilestoreData, setCreateFilestoreData] = useState(undefined);
    const [error, setError] = useState(undefined);

    useHotkeys("esc", () => navigateBack());

    useEffect(() => {
        const getDataApiEndpoint = apiEndpoints.newFilestoreOptions;
        createEmptyApiResponse(`get`, getDataApiEndpoint.schemaPath).then(emptyApiResponse => {
            if (!data && emptyApiResponse) setData(emptyApiResponse);
        });
        apiRequest(setData, setError, `get`, getDataApiEndpoint.constructUrl(siteId)).then(apiRequestSuccess => {
            if (apiRequestSuccess) {
                setLoaded(true);
            }
        })
    }, []);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const navigateBack = () => navigate(routeWithParams(routes.site.path, {id: siteId}));

    const createFilestore = () => {
        if (!name) {
            window.alert(t("filestoreNameRequired"));
            return;
        }
        setIsCreatingFilestore(true);
        apiRequest(
            setCreateFilestoreData,
            setError,
            "post",
            apiEndpoints.newFilestore.constructUrl(),
            "",
            {
                siteId: siteId,
                name: name,
                storageLocation: storageLocation,
                createdBy: authInfo.email,
                status: 0
            }
        ).then(apiRequestSuccess => {
            if (!apiRequestSuccess) alert(t("errorCreatingFilestore"));
            setIsCreatingFilestore(false);
        });
    }

    useEffect(() => {
        if (createFilestoreData === undefined) return;
        if (!!createFilestoreData.filestoreId) navigate(routeWithParams(routes.filestore.path, {id: createFilestoreData.filestoreId}));
    }, [createFilestoreData]);

    if (data === undefined) {
        return <FullPageLoading/>;
    }

    return (
        <>
            <PageTitle className="align-items-center d-flex">
                <div>
                    {loaded ? (
                        <>
                            <PageBackButton
                                className="mb-1"
                                baseUrl={routeWithParams(routes.site.path, {id: siteId})}
                            >
                                {data.siteName}
                            </PageBackButton>
                            {t("newFilestore")}
                        </>
                    ) : (
                        <>
                            <PageBackButton className="mb-1" baseUrl={null}></PageBackButton>
                            <LoadingSpinner size={46}/>
                        </>
                    )}
                </div>
                <div className="flex-grow-1"></div>
            </PageTitle>
            {data.permission ? (
                <div className="d-flex flex-wrap">
                    <div className="col-12">
                        <Card>
                            <div className="fw-bold text-size-lg">{t("summary")}</div>
                            <div className="d-flex mt-2">
                                <TitleValueInput
                                    title={`${t("name")}:`}
                                    className="flex-grow-1"
                                    value={name}
                                    setValue={setName}
                                />
                                {!name && (
                                    <>
                                        <FontAwesomeIcon icon={faExclamationCircle} className="text-color-accent"
                                                         data-tooltip-id="name-required-tooltip"/>
                                        <Tooltip
                                            id={`name-required-tooltip`}
                                            place="left"
                                            content={t("siteNameRequired")}
                                            style={tooltipStyles}
                                        />
                                    </>
                                )}
                            </div>
                            <TitleValueLine
                                loaded={loaded}
                                title={`${t("site")}:`}
                                value={data.siteName}
                            />
                            <TitleValueLine
                                loaded={loaded}
                                title={`${t("storageLocation")}:`}
                                value={storageLocation}
                            />
                        </Card>
                    </div>
                    <div className="col-12 my-2 my-lg-3 d-flex justify-content-center">
                        {isCreatingFilestore ? (
                            <LoadingSpinner size={38}/>
                        ) : (
                            <>
                                <div className="col-6 text-end">
                                    <button type="button" className="btn bg-accent me-3 text-white"
                                            onClick={() => createFilestore()} disabled={!name}>
                                        {t("save")}
                                    </button>
                                </div>
                                <div className="col-6">
                                    <button type="button" className="btn bg-med ms-3 text-white" onClick={() => {
                                        navigateBack()
                                    }}>
                                        {t("cancel")}
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            ) : (

                <div className="d-flex justify-content-center align-items-center flex-grow-1">
                    <div className="text-center">
                        <FontAwesomeIcon icon={faLock} size="3x" style={{marginBottom: "8px"}}/>
                        <div className="fw-bold text-size-xl">{t("oops")}</div>
                        <div className="text-color-med">{t("noPermissionToCreateFilestore")}</div>
                    </div>
                </div>
            )}
        </>
    );
}

export { NewFilestore };

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiEndpoints, apiRequest, createEmptyApiResponse } from "services/api";
import { Card, FullPageLoading, LinkWithParams, PageTitle, SearchableList, tooltipStyles } from "components/utils/ui";
import { routes } from "helpers/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import { uniqueId } from "helpers/random";

const Robots = () => {
    const {t} = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);

    useEffect(() => {
        const getDataApiEndpoint = apiEndpoints.robots;
        createEmptyApiResponse(`get`, getDataApiEndpoint).then(emptyApiResponse => {
            if (!data && emptyApiResponse) setData(emptyApiResponse);
        });
        apiRequest(setData, setError, `get`, getDataApiEndpoint).then(apiRequestSuccess => {
            if (apiRequestSuccess) setLoaded(true);
        })
    }, []);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const robotsTableHeaders = {
        name: t("name"),
        status: t("status"),
        calibrationStatus: t("calibrationStatus"),
        maintenanceStatus: t("maintenanceStatus"),
        assignee: t("assignee"),
        location: t("location"),
        thisWeek: t("thisWeek"),
        nextWeek: t("nextWeek"),
        swVersion: t("swVersion")
    }

    const processRobots = (robots) => {
        return robots.map(robot => {
            return {
                name: (<LinkWithParams baseUrl={routes.robot.path} params={{id: robot.id}} text={robot.name}
                                       key={uniqueId()}/>),
                status: t(`api:robot_status_list.${robot.status}`),
                calibrationStatus: t(`api:robot_calibration_status_list.${robot.calibrationStatus}`),
                maintenanceStatus: t(`api:robot_maintenance_status_list.${robot.maintenanceStatus}`),
                assignee: (<LinkWithParams baseUrl={routes.operator.path} params={{id: robot.assignee.id}}
                                           text={robot.assignee.name} key={uniqueId()}/>),
                location: robot.location,
                thisWeek: (() => {
                    const deployments = robot.thisWeek.reduce((acc, e, index) => {
                        if (index > 0) {
                            acc.push(", ");
                        }
                        acc.push(<LinkWithParams baseUrl={routes.deployment.path} params={{id: e.id}} text={e.name}
                                                 key={uniqueId()}/>);
                        return acc;
                    }, []);
                    return <>{deployments}</>;
                })(),
                nextWeek: (() => {
                    const deployments = robot.nextWeek.reduce((acc, e, index) => {
                        if (index > 0) {
                            acc.push(", ");
                        }
                        acc.push(<LinkWithParams baseUrl={routes.deployment.path} params={{id: e.id}} text={e.name}
                                                 key={uniqueId()}/>);
                        return acc;
                    }, []);
                    return <>{deployments}</>;
                })(),
                swVersion: robot.multipleSwVersions ? t("various") : robot.swVersion
            };
        });
    };

    if (data === undefined) {
        return <FullPageLoading/>;
    }

    return (
        <>
            <PageTitle className="align-items-center d-flex">
                <div>
                    {t("robots")}
                </div>
                <div className="flex-grow-1"></div>
                <div className="clickable text-end text-decoration-none">
                    <LinkWithParams data-tooltip-id="new-robot-tooltip" baseUrl={routes.newRobot.path}>
                        <FontAwesomeIcon icon={faPlus} className="text-color-accent"/>
                    </LinkWithParams>
                </div>
                <Tooltip
                    id={`new-robot-tooltip`}
                    place="left"
                    content={t("newRobot")}
                    style={tooltipStyles}
                />
            </PageTitle>
            <Card>
                <SearchableList
                    loaded={loaded}
                    title={t("allRobots")}
                    columnNames={robotsTableHeaders}
                    data={processRobots(data.robots)}
                />
            </Card>
        </>
    )
}

export { Robots };
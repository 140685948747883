import { useEffect, useState } from "react";
import { apiEndpoints, apiRequest } from "services/api";
import { LoadingSpinner, SearchableSelect } from "components/utils/ui";

const RobotGenerationSelector = ({loaded, robotGeneration, setRobotGeneration}) => {
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);

    useEffect(() => {
        const getDataApiEndpoint = apiEndpoints.robotGenerationList;
        apiRequest(setData, setError, `get`, getDataApiEndpoint);
    }, []);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const processOptions = (data) => {
        return data.reduce((obj, value) => {
            obj[value] = value;
            return obj;
        }, {});
    }

    if (data === undefined || !loaded) {
        return <LoadingSpinner/>;
    }

    return (
        <SearchableSelect loaded={true} options={processOptions(data)} setSelectedOption={setRobotGeneration}
                          selectedOption={robotGeneration}/>
    )

}

export { RobotGenerationSelector };